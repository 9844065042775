import React, { useRef, useState } from "react";
import { Button, Modal } from "../../../share/InsightUI";
import ElementStats from "./ElementStats";
import InsightechIcon from "../../../share/InsightechIcon";
import SelectorDropdown from "../../FilterPanel/components/SelectorDropdown";
import useClickOutside from "../../../../hooks/useClickOutside";

const ClickFilterModal = ({
  isVisible,
  handleClose,
  filterElementData,
  listFilterMode,
  listFilterAction,
  listFilterScrollPixel,
  filterModalElementContext,
  handleChangeActionType,
  handleChangeListFilterScrollPixel,
  handleClickFilterView,
  allReplayClickFilters,
}) => {
  const [isClickFilterDropdownOpen, setIsClickFilterDropdownOpen] =
    useState(false);
  const triggerButtonRef = useRef(null);
  const clickFilterRef = useRef(null);
  useClickOutside(clickFilterRef, () => setIsClickFilterDropdownOpen(false));

  const getFilteredReplayClickFilters = (filterModalElementContext) => {
    const filteredFilters = {};

    // Always include basic filters
    ["CLICK", "NO_CLICK", "LAST_CLICK", "RAGE_CLICK"].forEach((key) => {
      filteredFilters[key] = allReplayClickFilters[key];
    });

    if (!filterModalElementContext.element) {
      return filteredFilters;
    }

    const element = filterModalElementContext.element;

    const allNodesText = getDirectTextContent(element);

    // Add CONTENT_LOADED if element has more than one text node child
    if (
      allNodesText.length > 0 &&
      element.childNodes.length >= 1 &&
      element.childNodes[0].nodeType === 3 &&
      element.childNodes[0] !== ""
    ) {
      filteredFilters["CONTENT_LOADED"] =
        allReplayClickFilters["CONTENT_LOADED"];
    }

    // Add LAST_FIELD_CLICK if element is INPUT, TEXTAREA, or SELECT
    if (["INPUT", "TEXTAREA", "SELECT"].indexOf(element.tagName) > -1) {
      filteredFilters["LAST_FIELD_CLICK"] =
        allReplayClickFilters["LAST_FIELD_CLICK"];
    }

    // Add INPUT related filters if element is INPUT or TEXTAREA
    if (["INPUT", "TEXTAREA"].indexOf(element.tagName) > -1) {
      filteredFilters["INPUT"] = allReplayClickFilters["INPUT"];
      filteredFilters["NO_INPUT"] = allReplayClickFilters["NO_INPUT"];
      filteredFilters["CLICK_NO_INPUT"] =
        allReplayClickFilters["CLICK_NO_INPUT"];
    }

    return filteredFilters;
  };

  // Helper function to get direct text content from an element
  const getDirectTextContent = (element) => {
    if (!element) return [];

    const textNodes = [];
    for (let i = 0; i < element.childNodes.length; i++) {
      const node = element.childNodes[i];
      if (node.nodeType === 3 && node.textContent.trim() !== "") {
        textNodes.push(node.textContent.trim());
      }
    }
    return textNodes;
  };

  return (
    <Modal
      width={"600px"}
      handleClose={handleClose}
      isVisible={isVisible}
      title={"Create Filter Condition"}
      footer={
        <>
          <Button onClick={handleClose} variant="secondary" size="small">
            Cancel
          </Button>
          <Button
            onClick={handleClickFilterView}
            variant="primary"
            size="small"
          >
            View Performance
          </Button>
        </>
      }
      additionalInfo={
        listFilterMode === "click" ? (
          <div className="footer-element-stats">
            <div className="mb-3">Element Summary</div>
            <ElementStats data={filterElementData} viewSize="lg" />
          </div>
        ) : null
      }
    >
      <div className="click-filter-form">
        <span>
          Find <b> Pageviews </b> that{" "}
        </span>

        {listFilterMode === "click" && (
          <>
            <div className={`attribute-selector`} ref={clickFilterRef}>
              <div
                ref={triggerButtonRef}
                className="selected-attribute"
                onClick={() =>
                  setIsClickFilterDropdownOpen(!isClickFilterDropdownOpen)
                }
              >
                <div className="label-group">
                  {allReplayClickFilters[listFilterAction].label}
                </div>

                <InsightechIcon
                  name={isClickFilterDropdownOpen ? "ChevronUp" : "ChevronDown"}
                />
              </div>

              {isClickFilterDropdownOpen && (
                <SelectorDropdown
                  menuType="replay-click"
                  searchText=""
                  setSearchText={() => {}}
                  searchPlaceholder=""
                  selectedData={listFilterAction}
                  filters={getFilteredReplayClickFilters(
                    filterModalElementContext
                  )}
                  handleEventTypeChange={handleChangeActionType}
                  setIsDropdownOpen={setIsClickFilterDropdownOpen}
                  renderIcon={() => null}
                  triggerRef={triggerButtonRef}
                />
              )}
            </div>
            <span>the selected element</span>
          </>
        )}
        {listFilterMode === "scroll" && (
          <>
            {""} reach to
            <input
              type="text"
              className="form-control form-control-sm d-inline-block mx-2"
              style={{ width: 150 }}
              value={listFilterScrollPixel}
              onChange={handleChangeListFilterScrollPixel}
            />
            pixels deep
          </>
        )}
      </div>
    </Modal>
  );
};

export default ClickFilterModal;
