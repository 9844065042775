import React, { useCallback, useContext, useEffect, useState } from "react";
import SearchBar from "../../../../share/SearchBar";
import { UserDataContext } from "../../../UserData";
import { Tooltip as ReactTooltip } from "react-tooltip";
import userFilterTooltips from "../../../../../inc/tooltips/userFilterTooltips.json";
import eventFilterTooltips from "../../../../../inc/tooltips/eventFilterTooltips.json";
import UpgradeFlag from "../UpgradeFlag";
import { navigateToPlanUpgrade } from "../../../../../utils";

// Get tooltip content based on menu type
const getTooltipContent = (key, menuType) => {
  return menuType === "event"
    ? eventFilterTooltips[key]
    : userFilterTooltips[key];
};

const SelectorDropdown = (props) => {
  const {
    menuType,
    searchText,
    setSearchText,
    searchPlaceholder = "",
    selectedData,
    filters,
    handleEventTypeChange,
    setIsDropdownOpen,
    renderIcon,
    triggerRef, // Reference to the button that opens this dropdown
  } = props;
  const [dropdownStyle, setDropdownStyle] = useState({});
  const { featureFlags } = useContext(UserDataContext);

  const hoverKeyGroup = ["Hover", "HOVER", "NO_HOVER", "HOVER_NO_CLICK"];

  // Group filters by category
  const getFiltersByCategory = () => {
    const categories = {};
    Object.entries(filters).forEach(([key, filter]) => {
      // Skip IPAddress option when blocking is enabled
      if (!featureFlags.isIPAddressAvailable && key === "IPAddress") {
        return;
      }
      // Skip Hover option for all account plans
      if (!featureFlags.isHoverAvailable && hoverKeyGroup.includes(key)) {
        return;
      }
      if (!categories[filter.category]) {
        categories[filter.category] = [];
      }
      categories[filter.category].push(key);
    });
    return categories;
  };

  // Filter options based on search
  const getFilteredCategories = () => {
    if (!searchText) return getFiltersByCategory();

    const categories = {};
    Object.entries(filters).forEach(([key, filter]) => {
      // Skip IPAddress option when blocking is enabled
      if (!featureFlags.isIPAddressAvailable && key === "IPAddress") {
        return;
      }
      if (filter.label.toLowerCase().includes(searchText.toLowerCase())) {
        if (!categories[filter.category]) {
          categories[filter.category] = [];
        }
        categories[filter.category].push(key);
      }
    });
    return categories;
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const needsUpgrade = useCallback(
    (eventType) => {
      if (eventType === "ContentLoad" || eventType === "CONTENT_LOADED") {
        return featureFlags.isContentLoadedFlag;
      }
      if (eventType === "CustomData") {
        return featureFlags.isDataLayerFlag;
      }
      if (eventType === "ApiError") {
        return featureFlags.isApiErrorFlag;
      }
      if (
        eventType === "JsError" ||
        eventType === "ErrorPage" ||
        eventType === "ErrorMessage"
      ) {
        return !featureFlags.hasErrorReport;
      }
    },
    [
      featureFlags.isContentLoadedFlag,
      featureFlags.isDataLayerFlag,
      featureFlags.isApiErrorFlag,
      featureFlags.hasErrorReport,
    ]
  );

  const handleOptionClick = (e, key) => {
    e.stopPropagation();

    if (needsUpgrade(key)) {
      navigateToPlanUpgrade();
      return;
    }

    handleEventTypeChange(key);
    setIsDropdownOpen(false);
    setSearchText("");
  };

  const updatePosition = useCallback(() => {
    if (triggerRef?.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calculate dimensions
      const dropdownWidth =
        window.innerWidth > 1280
          ? 933
          : window.innerWidth > 1015
          ? Math.min(650, window.innerWidth * 0.9)
          : Math.min(380, window.innerWidth * 0.9);
      const dropdownHeight = 500; // max-height of the dropdown

      // Calculate left position
      let left = rect.left;
      if (left + dropdownWidth > viewportWidth) {
        left = Math.max(0, viewportWidth - dropdownWidth);
      }

      // Determine if there's enough space below
      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;
      // Determine if dropdown should appear above or below
      const showBelow =
        spaceBelow >= dropdownHeight || spaceBelow >= spaceAbove;

      const position = showBelow
        ? {
            top: `${rect.bottom + 5}px`,
            transformOrigin: "top left",
            maxHeight: `${spaceBelow - 10}px`, // Account for gap
          }
        : {
            bottom: `${viewportHeight - rect.top + 5}px`,
            transformOrigin: "bottom left",
            maxHeight: `${spaceAbove - 10}px`, // Account for gap
          };

      setDropdownStyle({
        ...position,
        left: `${left}px`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable body scroll when dropdown is open
  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);

    // Store current scroll positions and disable scrolling
    const scrollY = window.scrollY;
    const filtersPanel = document.querySelector(".filters-panel");
    const filterPanel = document.querySelector(".filter-panel");
    const scrollableContainers = [
      ...(filterPanel
        ? filterPanel.querySelectorAll(".container-body, .rule-group")
        : []),
      filtersPanel,
    ].filter(Boolean); // Remove null/undefined values

    // Store original overflow values
    const originalOverflows = scrollableContainers.map(
      (container) => container.style.overflow
    );

    // Disable main page scroll
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    // Disable scrolling for all containers
    scrollableContainers.forEach((container) => {
      container.style.overflow = "hidden";
    });

    // Cleanup: restore scroll positions and re-enable scrolling
    return () => {
      window.removeEventListener("resize", updatePosition);

      // Re-enable main page scroll
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);

      // Restore original overflow values
      scrollableContainers.forEach((container, index) => {
        container.style.overflow = originalOverflows[index] || "auto";
      });
    };
  }, [updatePosition]);

  return (
    <div className={`attribute-dropdown ${menuType}`} style={dropdownStyle}>
      {menuType !== "replay-click" && (
        <SearchBar
          searchInputText={searchText}
          handleKeywordChange={handleSearchChange}
          placeholder={searchPlaceholder}
        />
      )}

      <div className={`categories-container ${menuType}`}>
        {Object.entries(getFilteredCategories()).map(
          ([category, eventKeys]) => (
            <div key={category} className={`attribute-category ${menuType}`}>
              <div className="category-header">
                {category !== "undefined" && category}
              </div>
              {eventKeys.map((key) => (
                <div className="attribute-option-cell" key={key}>
                  <div
                    className={`attribute-option ${
                      selectedData === key ? "selected" : ""
                    }`}
                    onClick={(e) => handleOptionClick(e, key)}
                    data-tooltip-id="filter-option-tooltip"
                    data-tooltip-content={getTooltipContent(key, menuType)}
                  >
                    <span className="option-icon">
                      {renderIcon(filters[key]?.key)}
                    </span>

                    <span>{filters[key].label}</span>
                    {needsUpgrade(key) && (
                      <UpgradeFlag
                        isIconOnly={true}
                        id={`filter-${filters[key].label}`}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
      <ReactTooltip id="filter-option-tooltip" className="tooltip-sm" />
    </div>
  );
};

export default SelectorDropdown;
