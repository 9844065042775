import React from "react";
import ConversionsBuilder from "../../../../components/app/ConversionsBuilder";
import withProfileAdminCheck from "../../../../components/hoc/withProfileAdminCheck";

const Conversions = () => {
  return (
    <React.Fragment>
      <ConversionsBuilder />
    </React.Fragment>
  );
};

export default withProfileAdminCheck(Conversions);