import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

/**
 * StackedBarChart - A reusable stacked bar chart component for displaying distribution data
 *
 * @param {Object} props
 * @param {Array} props.segments - Array of segment objects with value, color, label, and icon properties
 * @param {string} props.tooltipId - ID of the tooltip to use (optional)
 * @param {string} props.className - Additional CSS class names (optional)
 * @returns {JSX.Element}
 */
const StackedBarChart = ({ segments = [], tooltipId, className = "" }) => {
  // Calculate total value and percentages
  const total = segments.reduce(
    (sum, segment) => sum + (segment.value || 0),
    0
  );

  if (total === 0 || segments.length === 0) {
    return <div className="stacked-bar-chart-container">No data</div>;
  }

  // Calculate percentage for each segment
  const segmentsWithPercent = segments.map((segment) => {
    const value = segment.value || 0;
    const percent = total > 0 ? Math.round((value / total) * 100) : 0;
    return { ...segment, percent };
  });

  // Create tooltip content
  const tooltipContent = segmentsWithPercent
    .map((segment) => `${segment.label}: ${segment.percent}%`)
    .join(" | ");

  const tooltipProps = tooltipId
    ? {
        "data-tooltip-id": tooltipId,
        "data-tooltip-content": tooltipContent,
      }
    : {};

  return (
    <div className={`stacked-bar-chart-container ${className}`}>
      <div className="stacked-bar-chart" {...tooltipProps}>
        {segmentsWithPercent.map(
          (segment, index) =>
            segment.percent > 0 && (
              <div
                key={index}
                className="bar-segment"
                style={{
                  width: `${segment.percent}%`,
                  backgroundColor: segment.color,
                }}
              >
                {segment.percent >= 15 && segment.icon && (
                  <span className="segment-icon">{segment.icon}</span>
                )}
                {segment.percent >= 6 && `${segment.percent}%`}
              </div>
            )
        )}
      </div>
    </div>
  );
};

StackedBarChart.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    })
  ).isRequired,
  tooltipId: PropTypes.string,
  className: PropTypes.string,
};

export default StackedBarChart;
