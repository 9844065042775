import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { FaCheck } from "react-icons/fa6";

const SegmentedButton = ({ segments, value, onChange, className }) => {
  return (
    <div className={`segmented-button ${className || ""}`}>
      {segments.map((segment, index) => (
        <button
          key={segment.value}
          className={`segment ${value === segment.value ? "selected" : ""} ${
            index === 0 ? "first" : index === segments.length - 1 ? "last" : ""
          }`}
          onClick={() => onChange(segment)}
        >
          {value === segment.value && (
            <FaCheck className="check-icon" size={14} />
          )}
          <span>{segment.label}</span>
        </button>
      ))}
    </div>
  );
};

SegmentedButton.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SegmentedButton;
