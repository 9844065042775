import { useState, useContext, useEffect } from "react";
import { UserDataContext } from "../../UserData";
import { dateRanges } from "../../../../helpers/dateRange";
import {
  deepCopy,
  seggregatePageContainers,
  setDateRangeLabel,
} from "../../../../utils";
import {
  conditionFixture,
  includedPageContainersFixture,
  excludedPageContainersFixture,
} from "../../../../fixtures/filters";
import { SegmentsContext } from "../../../../context/Segments";

import moment from "moment";
import { deleteReportById } from "../../../../lib/firebase/reports";

const useReports = (props) => {
  //state
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [originalSegments, setOriginalSegments] = useState({});
  const [deleteReportId, setDeleteReportId] = useState(null);
  const [isDeleteModalHidden, setIsDeleteModalHidden] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  //context
  const {
    setIsDateCompareOn,
    setIsSegmentCompareOn,
    initialDates,
    setStartDate,
    setEndDate,
    setCompareStartDate,
    setCompareEndDate,
    setAppliedFilters,
    activeProfileId,
    setUserConditions,
    setIncludedPageContainers,
    setExcludedPageContainers,
    setSelectedStartDate,
    setSelectedEndDate,
    setSelectedDateRange,
    setMySelectedDates,
    setReloadApi,
    setQueryId,
    setCachedFilters,
    reports,
    setReports,
    reportNames,
    setReportNames,
    selectedReport,
    setSelectedReport,
    isReportSelected,
    setIsReportSelected,
    setOnlyRecordedSessions,
  } = useContext(UserDataContext);
  const { setSelectedSegments, setComparedSelectedSegments, segments } =
    useContext(SegmentsContext);

  //constant
  const today = moment();

  const resetReport = () => {
    //reset segments
    setSelectedSegments({ ...originalSegments });
    //reset filters
    setUserConditions([deepCopy(conditionFixture)]);
    setIncludedPageContainers(deepCopy(includedPageContainersFixture));
    setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
    //reset date range
    setSelectedStartDate(initialDates.startDate);
    setStartDate(initialDates.startDate);
    setSelectedEndDate(initialDates.endDate);
    setMySelectedDates({
      startDate: initialDates.startDate,
      endDate: initialDates.endDate,
    });
    setEndDate(initialDates.endDate);
    setSelectedDateRange(initialDates);

    //reset apply
    setAppliedFilters({});
    setCachedFilters({});
    setReloadApi(true);
    setQueryId(null);
    setIsReportSelected(false);
  };

  // Delete button on the report list row
  const handleDeleteButton = (e) => {
    setDeleteReportId(e.currentTarget.dataset["reportid"]);
    setIsDeleteModalHidden(false);
  };

  // Delete confirmation on the delete popup modal
  const handleConfirmReportDelete = () => {
    setIsDeleting(true);
    deleteReportById(activeProfileId, deleteReportId)
      .then(() => {
        const updatedReports = reports.filter(
          (report) => report.Id !== deleteReportId
        );
        const updatedReportNames = reportNames.filter(
          (reportName) => reportName !== selectedReport.name
        );
        setReports(updatedReports);
        setReportNames(updatedReportNames);
        setIsDeleting(false);
        setSelectedReport({});
        resetReport();
        setIsDeleteModalHidden(true);
        props.history.push(`/profile/${activeProfileId}/report/list`);
      })
      .catch((error) => {
        console.error("Failed to delete report:", error);
        setIsDeleting(false);
        setIsDeleteModalHidden(true);
      });
  };

  // Set comparison mode ON or OFF based on the report
  const handleComparisonMode = (report) => {
    if (report?.comparison) {
      if (report.comparison.type === "date") {
        setIsSegmentCompareOn(false);
        setComparedSelectedSegments({});
        setIsDateCompareOn(true);
      } else if (report.comparison.type === "segment") {
        setIsDateCompareOn(false);
        setCompareStartDate(null);
        setCompareEndDate(null);
        setIsSegmentCompareOn(true);
      }
    } else {
      setIsDateCompareOn(false);
      setCompareStartDate(null);
      setCompareEndDate(null);
      setIsSegmentCompareOn(false);
      setComparedSelectedSegments({});
    }
  };

  // click report in list
  const handleClickReport = (report) => {
    const isReportExist = reports.some((r) => r.Id === report.Id);

    if (isReportExist) {
      setSelectedReport({ ...report });
      setIsDropdownVisible(false);
      if (report.reportType !== "external") {
        setIsReportSelected(true);
        // Set comparison mode ON or OFF based on the report
        handleComparisonMode(report);
      }
    }
  };

  //Pass selected segments, filters
  useEffect(() => {
    const newSelectedSegments = {};

    // External report does not have segments and don't need to apply filters
    if (isReportSelected && selectedReport.reportType !== "external") {
      selectedReport.segments.forEach((segmentId) => {
        // Only add the segment if it exists in the profile segments(avoid deleted segments)
        if (segments[segmentId]) {
          newSelectedSegments[segmentId] = true;
        }
      });

      const startDate =
        selectedReport.rolling === true
          ? moment().subtract(parseInt(selectedReport.rollingStart), "d")
          : moment(selectedReport.startDate?.toDate());
      const endDate =
        selectedReport.rolling === true
          ? moment().subtract(parseInt(selectedReport.rollingEnd), "d")
          : moment(selectedReport.endDate?.toDate());

      //Parse filters
      const filters = JSON.parse(selectedReport.filter);
      const [includedPageContainers, excludedPageContainers] =
        seggregatePageContainers(filters.pageContainers);

      setUserConditions(filters.userConditions);
      setIncludedPageContainers(includedPageContainers);
      setExcludedPageContainers(excludedPageContainers);
      setAppliedFilters({
        userConditions: [...filters.userConditions],
        pageContainers: [...filters.pageContainers],
      });
      setCachedFilters({
        profileId: activeProfileId,
        conditions: {
          userConditions: [...filters.userConditions],
          pageContainers: [...filters.pageContainers],
        },
      });

      //Segments
      setSelectedSegments({ ...newSelectedSegments });

      //Date range
      setSelectedStartDate(startDate);
      setStartDate(startDate);
      setSelectedEndDate(endDate);
      setEndDate(endDate);
      setMySelectedDates({
        startDate: startDate.startOf("day"),
        endDate: endDate.endOf("day"),
      });

      setDateRangeLabel(
        today,
        startDate,
        endDate,
        setSelectedDateRange,
        dateRanges
      );

      setOnlyRecordedSessions(selectedReport?.recordedSessions);

      // if report is in comparison mode
      if (
        selectedReport?.comparison &&
        Object.keys(selectedReport.comparison).length > 0
      ) {
        if (selectedReport.comparison.type === "date") {
          const compareStartDate =
            selectedReport.rolling === true
              ? moment().subtract(
                  parseInt(selectedReport.comparison.rollingStart),
                  "d"
                )
              : moment(selectedReport.comparison.startDate?.toDate());
          const compareEndDate =
            selectedReport.rolling === true
              ? moment().subtract(
                  parseInt(selectedReport.comparison.rollingEnd),
                  "d"
                )
              : moment(selectedReport.comparison.endDate?.toDate());

          setIsDateCompareOn(true);
          setCompareStartDate(compareStartDate);
          setCompareEndDate(compareEndDate);
        } else if (selectedReport.comparison.type === "segment") {
          setIsSegmentCompareOn(true);

          // transform segments ID array into a map: each ID is a key associated with a boolean value
          const comparedSegmentsMap = selectedReport.comparison.segments.reduce(
            (acc, segmentId) => {
              // Only add the segment if it exists in the profile segments(avoid deleted segments)
              if (segments[segmentId]) {
                acc[segmentId] = true;
              }
              return acc;
            },
            {}
          );
          setComparedSelectedSegments({ ...comparedSegmentsMap });
        }
      }

      setIsReportSelected(false);
      setReloadApi(true);
      setQueryId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportSelected]);

  return {
    isDropdownVisible,
    setIsDropdownVisible,
    resetReport,
    handleClickReport,
    originalSegments,
    setOriginalSegments,
    handleDeleteButton,
    handleConfirmReportDelete,
    isDeleteModalHidden,
    setIsDeleteModalHidden,
    isDeleting,
    setIsDeleting,
    handleComparisonMode,
  };
};

export default useReports;
