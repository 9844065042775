import React, { useContext, useState } from "react";
import Alert from "./../../../../components/share/Alert";
import { Button } from "./../../../../components/share/InsightUI";
import { Link } from "react-router-dom";
import { UserDataContext } from "./../../../../components/app/UserData";
import withProfileAdminCheck from "../../../../components/hoc/withProfileAdminCheck";
import "./style.scss";

const TrackingCode = (props) => {
  const { apiServerSubDomain } = useContext(UserDataContext);
  const [integrationMode, setIntegrationMode] = useState("onpage");
  const [alert, setAlert] = useState({ show: false, type: "", message: "", count: 0 });
  const profileId = props.match.params.profileId;
  const handleClickIntegrationMode = (e) => {
    const mode = e.currentTarget.dataset.mode;
    setIntegrationMode(mode);
  };

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4 tracking-code-card">
            <div className="card-body">
              <div className="mb-4">
                <Button
                  className={`method-toggle ${
                    integrationMode === "onpage" && "active"
                  } `}
                  data-mode="onpage"
                  onClick={handleClickIntegrationMode}
                >
                  <span className="fa fa-code"></span>
                  On-page Javascript
                </Button>
                <Button
                  className={`method-toggle ml-2 ${
                    integrationMode === "gtm" && "active"
                  } `}
                  data-mode="gtm"
                  onClick={handleClickIntegrationMode}
                >
                  <img src="/images/gtm-icon.svg" alt="" />
                  Google Tag manager
                </Button>
                <Button
                  className={`method-toggle ml-2 ${
                    integrationMode === "shopify" && "active"
                  } `}
                  data-mode="shopify"
                  onClick={handleClickIntegrationMode}
                >
                  <img src="/images/shopify-logo.svg" alt="" className="white-on-active" />
                  Shopify
                </Button>
              </div>
              {/* <p>Copy and Paste this code as after the <strong>&lt;head&gt;</strong> tag on all pages</p> */}
              {integrationMode === "onpage" && (
                <p>
                  Paste this code as high in the <strong>&lt;head&gt;</strong>{" "}
                  of the page as possible:
                </p>
              )}
              {integrationMode === "gtm" && (
                <>
                  <p>
                    Please follow the below steps to create the Insightech Tag
                    in Google Tag Manager:
                  </p>
                  <ul>
                    <li>
                      Create a <strong>Custom HTML</strong> tag named
                      "Insightech Tag"
                    </li>
                    <li>
                      Copy the code below and paste it to the Custom HTML tag.
                    </li>
                    <li>
                      In <strong>Advanced Settings</strong>, select{" "}
                      <strong>Once per page</strong> for{" "}
                      <strong>Tag firing options</strong>
                    </li>
                    <li>
                      Set trigger condition to <strong>All Pages</strong>.
                    </li>
                    <li>Publish the tag to live websites.</li>
                  </ul>
                </>
              )}
              {integrationMode === "shopify" && (
                <>
                  <p>
                    Due to Shopify restrictions on checkout, Insightech needs{" "}
                    <strong>2 tracking codes</strong>.
                  </p>
                  <h3>Main tracking code</h3>
                  <p>
                    Please add the following snippet to your theme.liquid file.
                    Paste this code as high in the <strong>&lt;head&gt;</strong>{" "}
                    of the page as possible:
                  </p>
                  {apiServerSubDomain && integrationMode === "shopify" && (
                      <code className="code-block mb-4">
                        {`<script>`}
                        {<br />}
                        {`!function(){ var async = function(c){ var d = document, s = d.createElement('script'), h = d.getElementsByTagName('head')[0]; s.src = 'https://cdn.insightech.com/insightech.mini.js'; if(c){s.addEventListener('load', function(e){c(null,e);}, false);} h.appendChild(s); }; async(function(){ insightech = new _InsightechObject("${profileId}:${apiServerSubDomain}"); }); }();`}
                        {<br />}
                        {`</script>`}
                      </code>
                    )}
                  
                  <p>
                    Alternatively you can use GTM by following instructions in the GTM tab above. Please make sure the GTM container is installed in the main page, not in the Shopify restricted environment.
                  </p>
                  <h3>Shopify checkout tracking code</h3>
                  <p>
                    This pixel will fire only on the checkout page. Please let
                    us know if the <strong>thank you page</strong> is also
                    restricted in order for insightech to be able to track
                    purchases.
                  </p>
                  <ul>
                    <li>
                      In Shopify store select: <strong>Settings</strong> &gt;{" "}
                      <strong>Customer events</strong> &gt;{" "}
                      <strong>Add custom pixel</strong>. Give your pixel the
                      name : <strong>Insightech</strong>
                    </li>
                    <li>
                      Check the correct <strong>Permission settings</strong> :
                      <ul>
                        <li>Ticked - ‘Analytics’</li>
                        <li>Unticked - ‘Marketing’ and ‘Preferences’</li>
                        <li>Set Data Sale to ‘Does not qualify’</li>
                      </ul>
                    </li>
                    <li>
                      Now <strong>paste the code snippet</strong> below in your
                      Insightech Custom Pixel.
                    </li>
                    <li>
                      Click <strong>Save</strong>. and <strong>Connect</strong>{" "}
                      to publish.
                    </li>
                  </ul>
                  {apiServerSubDomain && integrationMode === "shopify" && (
                      <code className="code-block mb-4">
                        {`<script>`}
                        {<br />}
                        {`!function(){_InsightechInit=function(){insightech=new _InsightechObject("${profileId}:${apiServerSubDomain}",api)};var e=document,n=e.createElement("script"),t=e.getElementsByTagName("head")[0];n.async=!0,n.src="https://cdn.insightech.com/insightech.shopify.js",t.appendChild(n)}();`}
                        {<br />}
                        {`</script>`}
                      </code>
                    )}
                </>
              )}
              {apiServerSubDomain && (integrationMode === "onpage" || integrationMode === "gtm") && (
                  <code className="code-block mb-4">
                    {`<script>`}
                    {<br />}
                    {`!function(){ var async = function(c){ var d = document, s = d.createElement('script'), h = d.getElementsByTagName('head')[0]; s.src = 'https://cdn.insightech.com/insightech.mini.js'; if(c){s.addEventListener('load', function(e){c(null,e);}, false);} h.appendChild(s); }; async(function(){ insightech = new _InsightechObject("${profileId}:${apiServerSubDomain}"); }); }();`}
                    {<br />}
                    {`</script>`}
                  </code>
                )}

              <h3>Advanced tracking settings</h3>
              <div className="alert alert-info">
                If you are going to test Insightech replay feature on Localhost
                or intranet sites, please note that all resource files (e.g.
                images, style sheets, fonts) must be accessible from Internet.
                Insightech archives the resource files so that the playback can
                be perfect even your site has changed.
              </div>

              <p>Click on the button to change advanced tracking settings</p>
              <Link
                className="btn btn-primary btn-sm"
                to={`/profile/${profileId}/admin/code/settings`}
              >
                Change Tracking Settings
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withProfileAdminCheck(TrackingCode);
