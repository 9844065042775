import React, { useState, useEffect, useContext, createContext } from "react";
import { withRouter } from "react-router-dom";
import api from "./../../api";
import uiApi from "./../../api/api-ui";
import config from "../../config/config.json";
import { ReducerContext } from "../../reducer/context";
import { dateRanges } from "./../../helpers/dateRange";
import { AuthContext } from "../auth/FirebaseAuthContext";
import { getProfileById } from "../../api/apiGlobal";
import {
  includedPageContainersFixture,
  excludedPageContainersFixture,
} from "./../../fixtures/filters";
import { deepCopy, seggregatePageContainers } from "./../../utils";
import { fetchFunnels } from "../../lib/firebase/funnels";
import { fetchReportById, fetchReports } from "../../lib/firebase/reports";
import { fetchUsersByProfileId } from "../../lib/firebase/user";
import { DEFAULT_FUNNEL_FOLDER_NAME } from "../../constants/funnels";

export const UserDataContext = createContext();

export const initialConditions = {
  userConditions: [
    {
      attribute: "",
      path: "",
      unit: "",
      operator: "",
      values: [""],
    },
  ],

  eventConditions: [
    {
      type: "",
      inclusion: true,
      conditions: [
        {
          attribute: "",
          path: "",
          unit: "",
          operator: "",
          values: [""],
        },
      ],
    },
  ],

  excludeEventConditions: [
    {
      type: "",
      inclusion: false,
      conditions: [
        {
          attribute: "",
          path: "",
          unit: "",
          operator: "",
          values: [""],
        },
      ],
    },
  ],
};

export const UserDataProvider = withRouter((props) => {
  const { children, location } = props;
  const { authUser, setAuthUser } = useContext(AuthContext);
  const { dispatch } = useContext(ReducerContext);
  const [apiServer, setApiServer] = useState(null);
  const [isApiServerLoading, setIsApiServerLoading] = useState(true);
  const [apiServerSubDomain, setApiServerSubDomain] = useState("");
  const [isUserHasProfileAccess, setIsUserHasProfileAccess] = useState(true);

  // store invites data for home screen
  const [invites, setInvites] = useState({
    loadingInvites: false,
    error: false,
    invitesData: [],
  });

  // store access permission data for home screen
  const [access, setAccess] = useState({
    loadingAccess: false,
    error: false,
    accounts: [],
    profiles: {},
  });

  // sidebar menu toggle state
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Load cached selected date range
  const initialDates = { ...dateRanges["yesterday"] };

  // Object to track the current selected date range
  // i.e. Today, Yesterday etc.
  const [selectedDateRange, setSelectedDateRange] = useState(initialDates);

  // The date ranges themselves
  const [datePreset, setDatePreset] = useState(null);
  const [startDate, setStartDate] = useState(initialDates.startDate);
  const [endDate, setEndDate] = useState(initialDates.endDate);
  const [selectedStartDate, setSelectedStartDate] = useState(
    initialDates.startDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState(initialDates.endDate);
  const [mySelectedDates, setMySelectedDates] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  // Date comparison
  const [isDateCompareOn, setIsDateCompareOn] = useState(false);
  const [compareStartDate, setCompareStartDate] = useState(null);
  const [compareEndDate, setCompareEndDate] = useState(null);

  // Segment Comparison
  const [isSegmentCompareOn, setIsSegmentCompareOn] = useState(false);

  // Session Recording Filters
  const [onlyRecordedSessions, setOnlyRecordedSessions] = useState(true);

  // Profile setting flags
  const [featureFlags, setFeatureFlags] = useState({
    isContentLoadedFlag: false,
    isDataLayerFlag: false,
    isApiErrorFlag: false,
    isLiteTrackingFlag: false,
    isTopNavbarFlag: false,
    isIPAddressAvailable: false,
    isHoverAvailable: false,
    hasErrorReport: false, // true, false
  });

  /**
   * Manage cached filters
   */
  let cachedFilter = {};
  try {
    cachedFilter = JSON.parse(cachedFilter);
  } catch {
    cachedFilter = null;
  }
  const [cachedFilters, setCachedFilters] = useState(cachedFilter);
  const [userConditions, setUserConditions] = useState([
    ...initialConditions.userConditions,
  ]);
  const [eventConditions, setEventConditions] = useState([
    ...initialConditions.eventConditions,
  ]);
  const [excludeEventConditions, setExcludeEventConditions] = useState([
    ...initialConditions.excludeEventConditions,
  ]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isInitialFiltersLoaded, setIsInitialFiltersLoaded] = useState(false);
  const [isOverrideFiltersLoaded, setIsOverrideFiltersLoaded] = useState(false);
  const [queryId, setQueryId] = useState(null);
  const [includedPageContainers, setIncludedPageContainers] = useState(
    deepCopy(includedPageContainersFixture)
  );
  const [excludedPageContainers, setExcludedPageContainers] = useState(
    deepCopy(excludedPageContainersFixture)
  );
  const [crossFilterMsg, setCrossFilterMsg] = useState("");

  /**
   * Manage Segments
   */

  const [selectedSegments, setSelectedSegments] = useState({});
  const [isInitialSegmentsLoaded, setIsInitialSegmentsLoaded] = useState(false);
  const [isCachedSegmentsLoaded, setIsCachedSegmentsLoaded] = useState(false);

  /**
   * Profile Data
   */
  const [profileUsers, setProfileUsers] = useState({ byId: {}, all: [] });

  // current profile ID
  const [activeProfileId, setActiveProfileId] = useState(null);

  // replay list
  const [replayList, setReplayList] = useState(null);
  const [replayListQuery, setReplayListQuery] = useState({
    id: null,
    key: null,
  });
  const [replayListPage, setReplayListPage] = useState(0);
  const [replayListPageSize, setReplayListPageSize] = useState(10);
  const [replayListTotal, setReplayListTotal] = useState(0);

  // funnel folders
  const [funnelFolders, setFunnelFolders] = useState([
    { label: "", value: "" },
  ]);
  const [selectedFunnelFolder, setSelectedFunnelFolder] = useState({
    label: DEFAULT_FUNNEL_FOLDER_NAME,
    value: DEFAULT_FUNNEL_FOLDER_NAME,
  });
  const [funnelFoldersMap, setFunnelFoldersMap] = useState();

  const [funnelStepsQuery, setFunnelStepsQuery] = useState({
    id: null,
    key: null,
  });

  const [funnels, setFunnels] = useState([]);

  const [reloadApi, setReloadApi] = useState(false);

  /**
   * Quick Report
   */
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const reportId = props.match.params?.reportId;
  const tempReport = props.match.params?.temp;
  const [isReportSelected, setIsReportSelected] = useState(false);
  const [reportNames, setReportNames] = useState([]);
  const [fullReportTypes, setFullReportTypes] = useState([]);
  const [isUpdatingReport, setIsUpdatingReport] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [action, setAction] = useState(null);

  // Error Report
  const [errorReportLink, setErrorReportLink] = useState(null);

  // Should this return a promise ?
  const loadAccountsThenSetAccess = () => {
    if (authUser) {
      api.loadAccounts(authUser).then((res) => {
        let ac = {
          loadingAccess: false,
          error: false,
          accounts: [],
          profiles: {},
        };
        if (res && res.data) {
          Object.keys(res.data).forEach((key) => {
            const account = res.data[key];
            ac.accounts.push(account);
            Object.keys(account.profiles).forEach((key) => {
              const profile = account.profiles[key];
              ac.profiles[profile.id] = profile.name;
            });
          });
          setAccess(ac);
        } else {
          ac = {
            loadingAccess: false,
            error: true,
            accounts: [],
          };
          setAccess(ac);
        }
      });
    }
  };

  const defineApiServer = (apiSubDomain) => {
    const server =
      config.dataApiLocalhost || `https://${apiSubDomain}.insightech.com`;

    setApiServerSubDomain(apiSubDomain);
    setApiServer(server);
    api.apiServer = server;
  };

  const loadInvitesThenSetAlert = () => {
    if (authUser) {
      uiApi.fetchInvite().then(({ data }) => {
        let inv = {
          loadingInvites: false,
          error: false,
          invitesData: [],
        };

        if (data && data?.success) {
          Object.keys(data.data).forEach((key) => {
            const invData = data.data[key].data;
            invData["userName"] = authUser.user.displayName;
            inv.invitesData.push(invData);
          });
          setInvites(inv);
        } else {
          inv.error = true;
          setInvites(inv);
        }
      });
    }
  };

  useEffect(() => {
    if (
      !isInitialFiltersLoaded &&
      cachedFilter &&
      !(location.state && location.state.overrideFilters)
    ) {
      const [includedPageContainers, excludedPageContainers] =
        seggregatePageContainers(cachedFilters.conditions.pageContainers);
      setUserConditions(cachedFilters.conditions.userConditions);
      setIncludedPageContainers(includedPageContainers);
      setExcludedPageContainers(excludedPageContainers);
      setAppliedFilters({
        userConditions: [...cachedFilters.conditions.userConditions],
        pageContainers: [...includedPageContainers, ...excludedPageContainers],
      });
    }
    setIsInitialFiltersLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialFiltersLoaded]);

  useEffect(() => {
    const users = {
      byId: {},
      all: [],
    };

    if (activeProfileId) {
      const requestedProfileId = activeProfileId; // In order to prevent race condition with async data fetching if profile has changed since request was sent
      setIsApiServerLoading(true); // Set loading to true before starting the request
      getProfileById(activeProfileId)
        .then((profile) => {
          // If activeProfileId changed since request was sent, ignore the response
          if (requestedProfileId !== activeProfileId) {
            console.log(
              "Profile ID changed, ignoring response of getProfileById"
            );
            return;
          }
          let isAdmin = false;
          if (!profile.apiServer) {
            console.warn("Profile is missing apiServer");
            setIsUserHasProfileAccess(false);
            return;
          }
          defineApiServer(profile.apiServer); // Todo remove, rely on profile.apiServer

          if (
            profile.isCurrentUserAdmin !== true &&
            profile.isCurrentUserAdmin !== false
          ) {
            console.warn("Profile is missing isCurrentUserAdmin");
            setIsUserHasProfileAccess(false);
            return;
          }
          isAdmin = profile.isCurrentUserAdmin;

          setAuthUser({
            ...authUser,
            isAdmin,
            isProfileAdmin: isAdmin, // Set profile-specific admin status
          });

          setIsApiServerLoading(false); // Only set loading to false after everything is done

          if (profile) {
            setFeatureFlags({
              isContentLoadedFlag:
                profile?.plan?.featureSet?.contentLoadLookbackDays === 0,
              isDataLayerFlag:
                profile?.plan?.featureSet?.datalayerLookbackDays === 0,
              isApiErrorFlag: !profile?.plan?.featureSet?.apiErrorAvailable,
              isLiteTrackingFlag:
                profile?.plan?.featureSet?.SessionReplaySamplingRate !== 100,
              isTopNavbarFlag: profile?.plan?.featureSet?.showHeaderUpgrade,
              isIPAddressAvailable: !profile?.blockIPAddress, // update if got new api res
              isHoverAvailable:
                profile?.plan?.featureSet?.hoverEventSearcheable,
              hasErrorReport: profile?.plan?.featureSet?.hasErrorReport, // undefined, true, false
            });
            setErrorReportLink(profile?.errorsReportLink || null);
          }
        })
        .catch((error) => {
          setIsUserHasProfileAccess(false);
          setIsApiServerLoading(false);
          if (error.response?.status === 404) {
            console.warn(`Profile not found (404): ${activeProfileId}`);
            dispatch({
              type: "SET_PROFILE_ERROR",
              message:
                "This profile does not exist or you don't have access to it. If you believe you should have access, please check your invites or talk to your administrator.",
            });
            props.history.push("/");
          } else {
            console.error("Error fetching profile:", error);
          }
        });

      // fetch funnels
      fetchFunnels({ profileId: activeProfileId })
        .then((res) => {
          if (requestedProfileId !== activeProfileId) {
            console.log(
              "Profile ID changed, ignoring response of fetchFunnels"
            );
            return;
          }
          let funnelObjs = [];
          res.forEach((funnelDoc) => {
            const funnelId = funnelDoc.id;
            funnelObjs.push({
              ID: funnelId,
              Name: funnelDoc.data().name,
              StepCount: funnelDoc.data().steps.length,
              StartedSessionCount: -1,
              ConversionRate: -1,
              CompletedSessionCount: -1,
            });
            setFunnels(funnelObjs);
          });
        })
        .catch((error) => {
          setIsUserHasProfileAccess(false);
          if (error.response?.status === 404) {
            console.warn(`Profile not found (404): ${activeProfileId}`);
          } else {
            console.error("Error fetching funnels:", error);
          }
        });

      //fetch temp report
      if (tempReport && reportId) {
        fetchReportById(activeProfileId, reportId, "temp")
          .then((res) => {
            if (requestedProfileId !== activeProfileId) {
              console.log(
                "Profile ID changed, ignoring response of fetchReportById"
              );
              return;
            }
            const fetchedReport = res.data();
            if (!fetchedReport) {
              console.warn("Report not found");
              return;
            }
            setSelectedReport({
              ...fetchedReport,
              Id: reportId,
            });
            setIsReportSelected(true);
          })
          .catch((error) => {
            setIsUserHasProfileAccess(false);
            if (error.response?.status === 404) {
              console.warn(`Profile not found (404): ${activeProfileId}`);
            } else {
              console.error("Error fetching report:", error);
            }
          });
      }

      //fetch reports
      fetchReports({ profileId: activeProfileId })
        .then((res) => {
          if (requestedProfileId !== activeProfileId) {
            console.log(
              "Profile ID changed, ignoring response of fetchReports"
            );
            return;
          }
          const updatedReports = [];
          const nameList = [];

          res.forEach((doc) => {
            nameList.push(doc.data().name);

            if (
              (doc.data().shared === false &&
                doc.data().ownerId === authUser.user.uid) ||
              doc.data().shared === true
            ) {
              if (reportId && reportId === doc.id) {
                updatedReports.push({
                  ...doc.data(),
                  Id: doc.id,
                });
                setSelectedReport({
                  ...doc.data(),
                  Id: doc.id,
                });
                setIsReportSelected(true);
              } else
                updatedReports.push({
                  ...doc.data(),
                  Id: doc.id,
                });

              updatedReports.sort((a, b) => b.updateTime - a.updateTime);
            }
          });

          setReports([...updatedReports]);
          setReportNames(nameList);
        })
        .catch((error) => {
          setIsUserHasProfileAccess(false);
          if (error.response?.status === 404) {
            console.warn(`Profile not found (404): ${activeProfileId}`);
          } else {
            console.error("Error fetching reports:", error);
          }
        });

      //fetch profile users
      fetchUsersByProfileId(activeProfileId).then((docs) => {
        if (requestedProfileId !== activeProfileId) {
          console.log(
            "Profile ID changed, ignoring response of fetchUsersByProfileId"
          );
          return;
        }
        docs.forEach((doc) => {
          users.byId[doc.id] = doc.data();
          users.all.push(doc.id);
          setProfileUsers(users);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfileId]);

  useEffect(() => {
    // clean up replay list data when conditions are changed
    setReplayList(null);

    // Wipe out filter cache if user switches profile
    // and reset value of conditions
    if (cachedFilters && activeProfileId) {
      if (
        cachedFilters.hasOwnProperty("profileId") &&
        cachedFilters.profileId !== activeProfileId
      ) {
        setUserConditions([...initialConditions.userConditions]);
        setEventConditions([...initialConditions.eventConditions]);
        setExcludeEventConditions([
          ...initialConditions.excludeEventConditions,
        ]);
        setIncludedPageContainers(deepCopy(includedPageContainersFixture));
        setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
        setCachedFilters(null);
        localStorage.removeItem("filters");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfileId, startDate, endDate]);

  useEffect(() => {
    let ac = {
      loadingAccess: true,
      error: false,
      accounts: [],
      profiles: {},
    };
    setAccess(ac);
    loadAccountsThenSetAccess();

    let inv = {
      loadingInvites: true,
      error: false,
      invitesData: [],
    };
    setInvites(inv);
    loadInvitesThenSetAlert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <UserDataContext.Provider
      value={{
        invites,
        setInvites,
        loadInvitesThenSetAlert,
        access,
        setAccess,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        loadAccountsThenSetAccess,
        apiServer,
        apiServerSubDomain,
        activeProfileId,
        setActiveProfileId,
        initialDates,
        selectedDateRange,
        setSelectedDateRange,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        replayList,
        setReplayList,
        replayListQuery,
        setReplayListQuery,
        replayListPage,
        setReplayListPage,
        replayListPageSize,
        setReplayListPageSize,
        replayListTotal,
        setReplayListTotal,
        cachedFilters,
        setCachedFilters,
        userConditions,
        setUserConditions,
        eventConditions,
        setEventConditions,
        excludeEventConditions,
        setExcludeEventConditions,
        appliedFilters,
        setAppliedFilters,
        queryId,
        setQueryId,
        selectedSegments,
        setSelectedSegments,
        isInitialSegmentsLoaded,
        isCachedSegmentsLoaded,
        setIsCachedSegmentsLoaded,
        setIsInitialSegmentsLoaded,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        datePreset,
        setDatePreset,
        isDateCompareOn,
        setIsDateCompareOn,
        compareStartDate,
        setCompareStartDate,
        compareEndDate,
        setCompareEndDate,
        isSegmentCompareOn,
        setIsSegmentCompareOn,
        onlyRecordedSessions,
        setOnlyRecordedSessions,
        profileUsers,
        setProfileUsers,
        funnelFolders,
        setFunnelFolders,
        selectedFunnelFolder,
        setSelectedFunnelFolder,
        funnelFoldersMap,
        setFunnelFoldersMap,
        funnelStepsQuery,
        setFunnelStepsQuery,
        isInitialFiltersLoaded,
        setIsInitialFiltersLoaded,
        isApiServerLoading,
        reloadApi,
        setReloadApi,
        includedPageContainers,
        setIncludedPageContainers,
        excludedPageContainers,
        setExcludedPageContainers,
        crossFilterMsg,
        setCrossFilterMsg,
        isOverrideFiltersLoaded,
        setIsOverrideFiltersLoaded,
        mySelectedDates,
        setMySelectedDates,
        funnels,
        setFunnels,
        reports,
        setReports,
        selectedReport,
        setSelectedReport,
        isReportSelected,
        setIsReportSelected,
        reportNames,
        setReportNames,
        fullReportTypes,
        setFullReportTypes,
        isUpdatingReport,
        setIsUpdatingReport,
        isSaveModalVisible,
        setIsSaveModalVisible,
        action,
        setAction,
        isUserHasProfileAccess,
        setIsUserHasProfileAccess,
        featureFlags,
        setFeatureFlags,
        errorReportLink,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
});
