import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import api from "./../../../../api";
import moment from "moment";
import Table from "./../../../../components/share/Table";
import Pagination from "../../../../components/app/Pagination";
import { withRouter } from "react-router-dom";
import { Button } from "./../../../../components/share/InsightechUI";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { UserDataContext } from "./../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import {
  captureExceptionToSentry,
  ellipsize,
  navigateToPlanUpgrade,
  parseUrl,
  kSeparatorDecimal,
} from "./../../../../utils";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";
import { ERRORS_MAPPED, DEFAULT_ERROR } from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";
import { AiOutlineCopy } from "react-icons/ai";
import Notification from "../../../../components/share/InsightUI/Notification";
import ExtensionModal from "./components/ExtensionModal";
import UpgradeFlag from "../../../../components/app/FilterPanel/components/UpgradeFlag";
import { MdOutlineExtension } from "react-icons/md";

function Pages(props) {
  const { location } = props;
  const listType = props.reportType || props.type;
  const profileId = props.match.params.profileId;
  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
    featureFlags,
  } = useContext(UserDataContext);
  const { selectedSegments, setSelectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [landingData, setLandingData] = useState([]);
  const [landingTotal, setLandingTotal] = useState([]);
  const [queryId, setQueryId] = useState(null);
  const [key, setKey] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [analysisOptions, setAnalysisOptions] = useState({
    url: "",
    segment: "",
  });
  const [groupBy, setGroupBy] = useState("pathname");

  // Live clickmap pop up
  const [clickedRowPageUrl, setClickedRowPageUrl] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupIndex, setPopupIndex] = useState();

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const [isCopied, setIsCopied] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const searchInputRef = useRef(null);

  const handleClickView = (e) => {
    const data = JSON.parse(e.currentTarget.dataset["payload"]);
    const [visitorId, sessionNumber] = data.exampleSessionId.split(":");
    props.history.push(
      `/profile/${profileId}/replay/session/${visitorId}/${sessionNumber}`,
      {
        replayOptions: {
          activeTab: "analytics",
          analyticsMode: "clickmap-all",
          url: data.page,
          segments: selectedSegments,
        },
      }
    );
  };

  const handleOpenPopup = (e) => {
    const rowIndex = parseInt(e.currentTarget.dataset["index"]);
    const url = JSON.parse(e.currentTarget.dataset["payload"]).page;

    setIsPopupVisible(true);
    setPopupIndex(rowIndex);
    setClickedRowPageUrl(url);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setPopupIndex(null);
    setClickedRowPageUrl(null);
  };

  const AnalyticsActionButton = ({
    className,
    onClick,
    tooltipContent,
    icon,
    ...rest
  }) => (
    <div
      className={`analytics-action ${className}`}
      onClick={onClick}
      data-tooltip-id="page-tooltip"
      data-tooltip-content={tooltipContent}
      {...rest}
    >
      {icon}
    </div>
  );

  const columns = useMemo(() => {
    let c = [
      {
        Header: "View Analytics",
        accessor: "exampleSessionId",
        Cell: (param) => {
          const { row } = param;
          const payload = JSON.stringify(row.original);

          // Shared "outlined" button for analyzing the live page
          const LivePageButton = (
            <AnalyticsActionButton
              className="solid"
              onClick={handleOpenPopup}
              tooltipContent="Analyze on the live page"
              icon={<MdOutlineExtension color="white" size={18} />}
              data-payload={payload}
              data-index={row.index}
            />
          );

          return (
            <div className="action-buttons">
              {row.original.exampleSessionId ? (
                <>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleClickView}
                    data-tooltip-id="page-tooltip"
                    data-tooltip-content="Analyse the page"
                    data-payload={payload}
                  >
                    Analyze
                  </Button>
                  {LivePageButton}
                </>
              ) : (
                row.index !== 0 && (
                  <>
                    <div className="position-relative">
                      <Button
                        color={
                          !!featureFlags.isLiteTrackingFlag
                            ? "outlined-primary"
                            : "disabled"
                        }
                        size="small"
                        data-tooltip-id="page-tooltip"
                        data-tooltip-content={
                          !!featureFlags.isLiteTrackingFlag
                            ? "Upgrade your plan to unlock all insights"
                            : "No replay available. Use the Chrome Extension to analyze on live page ."
                        }
                        data-payload={payload}
                        {...(!!featureFlags.isLiteTrackingFlag && {
                          onClick: () => navigateToPlanUpgrade(),
                        })}
                      >
                        Analyze
                      </Button>
                      {!!featureFlags.isLiteTrackingFlag && (
                        <div
                          className="position-absolute"
                          style={{
                            top: -6,
                            right: -6,
                          }}
                        >
                          <UpgradeFlag
                            isIconOnly={true}
                            id={`page-analysis-${listType}`}
                          />
                        </div>
                      )}
                    </div>
                    {LivePageButton}
                  </>
                )
              )}
            </div>
          );
        },
      },
      {
        Header: "Page URL",
        accessor: "page",
        Cell: (param) => {
          const { value } = param;
          if (value) {
            const formattedValue = parseUrl(value);
            return (
              <span
                className="ml-2 d-flex justify-content-between align-items-center"
                data-raw-url={value} // store the raw value for clipboard copy
              >
                <span
                  className="flex-grow-1"
                  data-tooltip-id="page-tooltip"
                  data-tooltip-content={value}
                >
                  {ellipsize(
                    formattedValue
                      ? `${formattedValue.pathname}${formattedValue.search}${formattedValue.hash}`
                      : value,
                    50
                  )}
                </span>
                <span
                  className="url-copy-button"
                  onClick={() => handleCopyUrl(param.row.original.page)}
                  data-tooltip-id="page-tooltip"
                  data-tooltip-content="Copy page url"
                >
                  <AiOutlineCopy size={14} />
                </span>
              </span>
            );
          } else {
            return (
              <span className="ml-2">
                <strong>(Grand Total)</strong>
              </span>
            );
          }
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The count of unique website visits from users, that visit each page."
          >
            Sessions
          </span>
        ),
        accessor: "sessions",
        Cell: ({ value }) => {
          return kSeparatorDecimal(value);
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The percentage of pageviews where at least one click event was recorded. (Pageviews with Clicks / Total Pageviews)."
          >
            Click Rate %
          </span>
        ),
        accessor: "clickRate",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The median depth that users vertically scroll to, as a percentage of the total page height, when loading each page."
          >
            Median Scroll Depth %
          </span>
        ),
        accessor: "medianScrollDepth",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The median time spent for each pageview from start to finish, calculated in seconds and displayed in the format hh:mm:ss."
          >
            Median Duration
          </span>
        ),
        accessor: "medianDuration",
        Cell: ({ value }) => {
          return moment
            .duration(value, "milliseconds")
            .format("HH:mm:ss", { trim: false });
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The percentage of sessions that visit a URL, and convert within the same session (Converted Sessions / Total Sessions)."
          >
            {listType !== "landing_page_list" && "Assisted"} Conversion %
          </span>
        ),
        accessor: "assistedConversionRate",
        Cell: ({ value }) =>
          value ? `${Math.round(value * 100 * 100) / 100}%` : "0.00%",
      },
    ];

    // Conditionally add columns based on `listType`
    // Add ""Page Views" column if not landing page list
    if (listType !== "landing_page_list") {
      const pageviewsColumn = {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The total number of page loads or URL loads for each URL shown."
          >
            Page Views
          </span>
        ),
        accessor: "pageviews",
        Cell: ({ value }) => {
          return kSeparatorDecimal(value);
        },
      };

      // Insert the page views at the 4nd column (index 3)
      c.splice(3, 0, pageviewsColumn);
    }

    // Add "Revenue" and "Avg Conversion Revenue" columns for landing page list
    if (listType === "landing_page_list") {
      c.push(
        {
          Header: (
            <span
              className="pageHeader"
              data-tooltip-id="page-tooltip"
              data-tooltip-content="The total revenue from sessions that visit a URL, and convert within the same session."
            >
              {listType !== "landing_page_list" && "Assisted"} Revenue $
            </span>
          ),
          accessor: "revenue",
          Cell: ({ value }) =>
            value ? `$${kSeparatorDecimal(value, 2, 2)}` : "$0.00",
        },
        {
          Header: (
            <span
              className="pageHeader"
              data-tooltip-id="page-tooltip"
              data-tooltip-content="The average revenue from sessions that visit a URL, and convert within the same session (Total Revenue / Total Converted Sessions)."
            >
              Avg Conversion Revenue $
            </span>
          ),
          accessor: "avgConvRevenue",
          Cell: ({ value }) =>
            value ? `$${kSeparatorDecimal(value, 2, 2)}` : "$0.00",
        }
      );
    }

    return c;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegments, analysisOptions, listType, page]);

  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readPagesList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readPagesList");
    api
      .readPagesList(authUser, {
        profileId,
        queryId,
        key,
        page,
        pageSize,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readPagesList({ queryId, key, page, pageSize });
        } else if (res.data.status === 200) {
          setIsLoadingData(false);
          setIsDataLoaded(true);
          setTotalRows(res.data.data.totalRows);
          setData(res.data.data.records ?? []);
          setTotal(res.data.data.metadata ? [res.data.data.metadata] : []);
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchPagesList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });

    api.cancel("queryPagesList");
    api.cancel("readPagesList");

    // Base params object
    const params = {
      profileId,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      filter: JSON.stringify(appliedFilters),
      pageURL: JSON.stringify({
        operator: groupBy === "pathname" ? "regexp" : "contains",
        values:
          groupBy === "pathname"
            ? [
                analysisOptions.url == ""
                  ? ""
                  : `^([^?#]*${analysisOptions.url}[^?#]*)`,
              ]
            : [analysisOptions.url],
      }),
      segments: JSON.stringify(segmentsArray),
      sort: "pageviews-desc",
      groupBy: groupBy,
    };

    api
      .queryPagesList(authUser, params)
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readPagesList({ queryId, key, page, pageSize });
          setQueryId(queryId);
          setKey(key);
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const readLandingPagesList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readLandingPagesList");
    api
      .readLandingPagesList(authUser, {
        profileId,
        queryId,
        key,
        page,
        pageSize,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readLandingPagesList({ queryId, key, page, pageSize });
        } else if (res.data.status === 200) {
          setTotalRows(res.data.data.totalRows);
          setLandingData(res.data.data.records ?? []);
          setLandingTotal(
            res.data.data.metadata ? [res.data.data.metadata] : []
          );
          setIsLoadingData(false);
          setIsDataLoaded(true);
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchLandingPagesList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });

    api.cancel("queryLandingPagesList");
    api.cancel("readLandingPagesList");

    // Base params object
    const params = {
      profileId,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      filter: JSON.stringify(appliedFilters),
      pageURL: JSON.stringify({
        operator: groupBy === "pathname" ? "regexp" : "contains",
        values:
          groupBy === "pathname"
            ? [
                analysisOptions.url == ""
                  ? ""
                  : `^([^?#]*${analysisOptions.url}[^?#]*)`,
              ]
            : [analysisOptions.url],
      }),
      segments: JSON.stringify(segmentsArray),
      sort: "sessions-desc",
      groupBy: groupBy,
    };

    api
      .queryLandingPagesList(authUser, params)
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readLandingPagesList({ queryId, key, page, pageSize });
          setQueryId(queryId);
          setKey(key);
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  // Click the "search" button to search the page URL
  const handleSearchUrl = () => {
    const value = searchInputRef.current.value;
    setAnalysisOptions({
      ...analysisOptions,
      url: value,
    });
  };

  // Pressing the "Enter" key on the search input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchUrl();
    }
  };

  // Handler for groupBy
  const handleChangeGroupOption = (e) => {
    const value = e.target.value;
    setGroupBy(value);
  };

  // Handler for copying table data to the clipboard
  const handleCopyTableData = async () => {
    try {
      const table = document.querySelector("#table-pages");

      // Extract the headers from the table
      const headers = [];
      table.querySelectorAll("thead th").forEach((header, index) => {
        if (index === 0) return; // Skip the first column
        headers.push(header.innerText);
      });

      // Prepare the headers for clipboard copy
      const headerRow = headers.join("\t");

      // Extract rows and get raw URLs for clipboard copy
      const rows = table.querySelectorAll("tbody tr");
      const tableData = [];
      rows.forEach((row) => {
        const rowData = [];
        row.querySelectorAll("td").forEach((cell, index) => {
          if (index === 0) return; // Skip the first column
          const rawUrl = cell.querySelector("[data-raw-url]");

          if (rawUrl) {
            rowData.push(rawUrl.getAttribute("data-raw-url")); // Use the raw URL value
          } else {
            rowData.push(cell.innerText); // Fallback to cell text for other columns
          }
        });
        tableData.push(rowData.join("\t"));
      });

      // Combine headers and rows
      const tableText = [headerRow, ...tableData].join("\n");
      await navigator.clipboard.writeText(tableText);

      setCopyMessage("Successfully copied page list data!");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      setIsCopied(false);
    }
  };

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopyMessage("Successfully copied page url!");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      setIsCopied(false);
    }
  };

  const handleCloseNotification = () => {
    setIsCopied(false);
  };

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel("queryPagesList");
      api.cancel("readPagesList");
      api.cancel("queryLandingPagesList");
      api.cancel("readLandingPagesList");
    };
  }, []);

  // Pagination
  useEffect(() => {
    if (queryId && key) {
      setIsLoadingData(true);
      if (listType === "landing_page_list") {
        readLandingPagesList({ queryId, key, page, pageSize });
      } else {
        readPagesList({ queryId, key, page, pageSize });
      }
      // Grand Total is the same on all pages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      profileId &&
      appliedFilters &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      !isDependenciesLoaded
    ) {
      setIsDependenciesLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    isDependenciesLoaded,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (isDependenciesLoaded && profileId) {
      if (listType === "landing_page_list") {
        fetchLandingPagesList();
      } else {
        fetchPagesList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    appliedFilters,
    analysisOptions,
    selectedSegments,
    startDate,
    endDate,
    isDependenciesLoaded,
    listType,
    groupBy,
  ]);

  useEffect(() => {
    if (location.state && location.state.analysisOptions) {
      setAnalysisOptions(location.state.analysisOptions);
      if (location.state.analysisOptions.segment) {
        setSelectedSegments({
          ...selectedSegments,
          [location.state.analysisOptions.segment]: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Alert show={alert.show} type={alert.type} message={alert.message} />

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="search-url form-inline">
                  <div className="search-area">
                    <input
                      defaultValue={analysisOptions.url}
                      ref={searchInputRef} // Attach ref to the input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={`Search for page ${
                        groupBy === "pathname" ? "path" : " URL"
                      }`}
                      onKeyDown={handleKeyDown} // Add keydown event handler
                    />
                    <div className="search-button">
                      <Button
                        size="flag"
                        color="aluminum"
                        onClick={handleSearchUrl}
                      >
                        search
                      </Button>
                    </div>
                  </div>
                  <select
                    className="form-control form-control-sm"
                    onChange={handleChangeGroupOption}
                    value={groupBy}
                  >
                    <option value="pathname" data-group-by="pathname">
                      View Page Paths
                    </option>
                    <option value="url" data-group-by="url">
                      View All URLs
                    </option>
                  </select>
                </div>
                <div>
                  <button className="copy-icon" onClick={handleCopyTableData}>
                    <AiOutlineCopy
                      data-tooltip-id="page-tooltip"
                      data-tooltip-content="Copy page table with full urls"
                    />
                  </button>
                </div>
              </div>

              <Table
                hScroll
                id="table-pages"
                data={
                  listType === "landing_page_list"
                    ? landingTotal.concat(landingData)
                    : total.concat(data)
                }
                columns={columns}
                isLoading={isLoadingData}
                isDataLoaded={isDataLoaded}
                loadingText={
                  <>
                    <i className="fa fa-spinner spin"></i> Loading Pages...
                  </>
                }
              />
              {!isLoadingData && (
                <Pagination
                  activePage={page + 1}
                  totalRows={totalRows}
                  pageSize={pageSize}
                  changeHandler={(pageNum, pageSize) => {
                    setPage(pageNum - 1);
                    setPageSize(pageSize);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isCopied && (
        <Notification message={copyMessage} onClose={handleCloseNotification} />
      )}
      {isPopupVisible && popupIndex && (
        <ExtensionModal
          isPopupVisible={isPopupVisible}
          handleClosePopup={handleClosePopup}
          clickedRowPageUrl={clickedRowPageUrl}
        />
      )}
      <ReactTooltip id="page-tooltip" className="tooltip-lg tooltip-with-url" />
    </>
  );
}

Pages.propTypes = {};
Pages.defaultProps = {};

export default withRouter(Pages);
