import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./style.scss";

const conditions = require("./../../../inc/replayEventConditions.json");
const operators = require("./../../../inc/operators.json");

function UrlFilterPanel(props) {
  const { filterConditionsInput, setFilterConditionsInput } = props;

  const handleChangeAttribute = (e) => {
    const value = e.target.value;

    setFilterConditionsInput({
      ...filterConditionsInput,
      attribute: value,
      operator: value === "PagePath" ? "regexp" : "is",
    });
  };

  const handleChangeOperator = (e) => {
    setFilterConditionsInput({
      ...filterConditionsInput,
      operator: e.target.value,
      values: [...filterConditionsInput.values], // Preserve values
    });
  };

  const handleChangeValue = (e) => {
    const index = e.target.dataset.index;
    const newValues = [...filterConditionsInput.values];
    newValues[index] = e.target.value;

    setFilterConditionsInput({ ...filterConditionsInput, values: newValues });
  };

  const handleClickAddValueField = () => {
    setFilterConditionsInput({
      ...filterConditionsInput,
      values: [...filterConditionsInput.values, ""],
    });
  };

  const handleClickRemoveValueField = (e) => {
    const index = parseInt(e.currentTarget.dataset.index, 10);
    const newValues = [...filterConditionsInput.values];

    if (newValues.length === 1) {
      newValues[0] = ""; // Reset to empty instead of deleting
    } else {
      newValues.splice(index, 1);
    }

    setFilterConditionsInput({ ...filterConditionsInput, values: newValues });
  };

  return (
    <div className="page-filter-group">
      {/** Condition Field */}
      <div className="filter-selectors">
        <div className="field-col">
          <select
            className="form-control form-control-sm"
            name="condition"
            value={filterConditionsInput?.attribute}
            onChange={handleChangeAttribute}
          >
            {Object.keys(conditions).map((key) => {
              const condition = conditions[key];
              return (
                <option key={condition.key} value={condition.key}>
                  {condition.label}
                </option>
              );
            })}
          </select>
        </div>

        {/** Operator Field */}
        {filterConditionsInput?.attribute !== "PagePath" ? (
          <div className="field-col">
            <select
              className="form-control form-control-sm"
              name="operator"
              value={filterConditionsInput?.operator}
              onChange={handleChangeOperator}
            >
              {operators["string"].map((operator) => (
                <option value={operator.key} key={operator.key}>
                  {operator.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <>
            <span className="ml-3 mr-3 mt-1">is</span>
            <FaRegQuestionCircle
              size={14}
              className="ml-2"
              style={{ marginTop: "4px" }}
              data-tooltip-id="tooltip-url-filter-panel"
              data-tooltip-content={
                "This pattern matches the page and all its variations, including anything after ? or #, like filters or anchors."
              }
            />
          </>
        )}
      </div>

      {/** Value Field */}
      {filterConditionsInput?.values.map((val, index) => {
        return (
          <div className="field-col value-field-container" key={index}>
            {index > 0 && <span className="conj">or</span>}
            <input
              type="text"
              className="form-control form-control-sm"
              name="value"
              data-index={index}
              value={val}
              onChange={handleChangeValue}
            />
            <Button
              className="btn-delete"
              data-index={index}
              size="small"
              icon="fas fa-times"
              onClick={handleClickRemoveValueField}
            ></Button>
          </div>
        );
      })}

      {/** OR Button */}
      <div className="field-col add-btn-container">
        <Button
          variant="secondary"
          size="small"
          onClick={handleClickAddValueField}
        >
          or
        </Button>
      </div>
      <ReactTooltip id="tooltip-url-filter-panel" className="tooltip-md" />
    </div>
  );
}

UrlFilterPanel.propTypes = {
  filterConditionsInput: PropTypes.object,
  setFilterConditionsInput: PropTypes.func,
};

export default UrlFilterPanel;
