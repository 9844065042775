import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as Icons from "../../assets/icons/MenuIconProvider";

const SettingMenu = ({ profileId }) => {
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);
  const location = useLocation();

  // Keep submenu open when on pages route, collapse when navigating elsewhere
  useEffect(() => {
    const isOnSettingsRoute = location.pathname.startsWith(
      `/profile/${profileId}/admin/`
    );

    setIsSettingsExpanded(isOnSettingsRoute);
  }, [location.pathname, profileId]);

  return (
    <nav
      className={
        "submenu-container" +
        (isSettingsExpanded ||
        location.pathname.startsWith(`/profile/${profileId}/admin/`)
          ? " expanded"
          : "")
      }
    >
      <div
        className={"sidebar-menu-item"}
        onClick={() => setIsSettingsExpanded(!isSettingsExpanded)}
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Setting"
      >
        <span className="nav-menu-icon">
          <Icons.Settings />
        </span>
        <span className="sidebar-menu-title">Settings</span>
      </div>
      {isSettingsExpanded && (
        <div className="submenu">
          <NavLink
            className="submenu-item"
            exact
            to={"/profile/" + profileId + "/admin/settings"}
            activeClassName="active"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Profile"
          >
            <span className="nav-menu-icon submenu-icon ">
              <Icons.SettingsProfile />
            </span>
            <span className="submenu-title">Profile</span>
          </NavLink>
          <NavLink
            className={
              "submenu-item" +
              (location.pathname.startsWith(
                "/profile/" + profileId + "/admin/users"
              )
                ? " active"
                : "")
            }
            exact
            to={"/profile/" + profileId + "/admin/users"}
            activeClassName="active"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Users"
          >
            <span className="nav-menu-icon submenu-icon ">
              <Icons.SettingsUsers />
            </span>
            <span className="submenu-title">Users</span>
          </NavLink>
          <NavLink
            className={
              "submenu-item" +
              (location.pathname ==
                "/profile/" + profileId + "/admin/code"
                ? " active"
                : "")
            }
            exact
            to={"/profile/" + profileId + "/admin/code"}
            activeClassName="active"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Tracking Code"
          >
            <span className="nav-menu-icon submenu-icon ">
              <Icons.SettingsCode />
            </span>
            <span className="submenu-title">Tracking Code</span>
          </NavLink>
          <NavLink
            className={
              "submenu-item" +
              (location.pathname.startsWith(
                "/profile/" + profileId + "/admin/code/settings"
              )
                ? " active"
                : "")
            }
            exact
            to={"/profile/" + profileId + "/admin/code/settings"}
            activeClassName="active"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Tracking Code Settings"
          >
            <span className="nav-menu-icon submenu-icon ">
              <Icons.SettingsTracking />
            </span>
            <span className="submenu-title">Tracking Settings</span>
          </NavLink>
          <NavLink
            className="submenu-item"
            exact
            to={"/profile/" + profileId + "/admin/conversions"}
            activeClassName="active"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Conversions"
          >
            <span className="nav-menu-icon submenu-icon">
              <Icons.SettingsConversions />
            </span>
            <span className="submenu-title">Conversions</span>
          </NavLink>
        </div>
      )}
      <ReactTooltip
        id="sidebar-menu-tooltip"
        className="tooltip-sm"
        place="right"
      />
    </nav>
  );
};

export default SettingMenu;
