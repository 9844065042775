import {
  getAuth,
  Firestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  getDocs,
  getDoc,
  deleteDoc,
} from "./index";

export const saveReport = (data, profileId, type) => {
  const collectionRef = collection(
    Firestore,
    "profiles",
    profileId,
    type === "temp" ? "reports_temp" : "reports_v2"
  );

  const utcNow = Date.now();

  const baseData = {
    ...data,
    ownerId: getAuth().currentUser.uid,
    creationTime: String(utcNow),
    updateTime: String(utcNow),
  };

  return addDoc(collectionRef, baseData);
};

export const updateReport = (data, profileId, id) => {
  const collectionRef = collection(
    Firestore,
    "profiles",
    profileId,
    "reports_v2"
  );
  const utcNow = Date.now();

  return updateDoc(doc(collectionRef, id), {
    ...data,
    updateTime: String(utcNow),
  });
};

export const fetchReportById = (profileId, reportId, type) => {
  const collectionRef = collection(
    Firestore,
    "profiles",
    profileId,
    type === "temp" ? "reports_temp" : "reports_v2"
  );

  return getDoc(doc(collectionRef, reportId));
};

export const fetchReports = ({ profileId }) => {
  return getDocs(collection(Firestore, "profiles", profileId, "reports_v2"));
};

export const deleteReportById = (profileId, reportId) => {
  return deleteDoc(
    doc(Firestore, "profiles", profileId, "reports_v2", reportId)
  );
};
