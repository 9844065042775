import React, { useContext, useState } from "react";
import { Button, Dropdown } from "../../share/InsightUI";
import SegmentedButton from "../../share/InsightechUI/SegmentedButton";
import CardCheckbox from "../../share/CardCheckbox";
import Collapsable from "../../share/Collapsable";
import ClickmapCard from "../../share/ClickmapCard";
import NoteCard from "../../share/NoteCard";
import ProgressBar from "../../share/ProgressBar";
import LoaderWithText from "./../../share/LoaderWithText";
import AlertBox from "../../share/InsightUI/AlertBox";
import moment from "moment";
import countries from "../../../inc/country.json";
import ElementStats from "./components/ElementStats";
import {
  deepCopy,
  capitalize,
  ellipsize,
  kSeparatorDecimal,
} from "../../../utils";
import { filterItems, querySelector, formatCss } from "../../../utils/replay";
import { ReducerContext } from "../../../reducer/context";
import { ADD_TOAST } from "../../../reducer/index";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import { eventFixture } from "../../../fixtures/filters";
import { UserDataContext } from "../UserData";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaRegHandPointer } from "react-icons/fa6";
import { PiCursorClickBold } from "react-icons/pi";
import { FaRegAngry } from "react-icons/fa";
import { LuGoal, LuCircleDollarSign } from "react-icons/lu";
import { BiCategoryAlt } from "react-icons/bi";

const ReplaySidePanelTabs = (props) => {
  const {
    initialTimestamp,
    activeTab,
    handleClickTab,
    pageviews,
    handleClickShowEventFilters,
    eventsCount,
    eventComponents,
    isClickmapDataLoading,
    isPageClickmapDataLoaded,
    analyticsMode,
    handleChangeAnalysisMode,
    hoveredData,
    isScrollmapDataLoading,
    isPageScrollmapDataLoaded,
    analyticsClickmapMode,
    handleChangeClickmapMode,
    allClickmapData,
    clickmapDataFilter,
    handleChangeClickmapDataFilter,
    clickmapLinks,
    replayIframeDoc,
    totalPageviews,
    handleClickShowFilterModal,
    analyticsFrictionmapMode,
    handleChangeFricitionmapMode,
    analyticsFormAnalysisMode,
    handleChangeFormAnalysisMode,
    mouseData,
    noDataAlert,
    setNoDataAlert,
    pageClickmapData,
    handleClickFiltersApplied,
    isNotesLoading,
    isNotesLoaded,
    notes,
    profileId,
    handleClickEditNote,
    handleClickDeleteNote,
    handleClickPlayNote,
  } = props;

  const { authUser } = useContext(AuthContext);
  const { dispatch } = useContext(ReducerContext);
  const { profileUsers } = useContext(UserDataContext);

  const [selectedFilters, setSelectedFilters] = useState({});

  const handleClickDetailCard = (e) => {
    const target = e.currentTarget;
    const filter = target.dataset["filter"];
    const key = target.dataset["key"];
    const filters = deepCopy(selectedFilters);
    if (target.checked) {
      filters[key] = JSON.parse(filter);
    } else {
      delete filters[key];
    }
    setSelectedFilters(filters);
  };

  const handleClickFindSimilar = () => {
    const filters = [];
    const eventFilters = [];
    Object.keys(selectedFilters).forEach((key) => {
      if (key === "pageUrl") {
        eventFilters.push({
          type: "VisitPage",
          inclusion: true,
          conditions: [
            {
              path: "",
              unit: "",
              ...selectedFilters[key][0],
            },
          ],
        });
      } else {
        filters.push(...selectedFilters[key]);
      }
    });
    if (!eventFilters.length) {
      eventFilters.push(deepCopy(eventFixture));
    }
    const filterObject = {
      userConditions: filters,
      pageContainers: [
        {
          inclusion: true,
          eventConditions: [...eventFilters],
        },
        {
          inclusion: false,
          eventConditions: [deepCopy(eventFixture)],
        },
      ],
    };
    props.history.push("/profile/" + profileId + "/replay/list", {
      overrideFilters: filterObject,
    });
  };

  const clickmapHover = (e) => {
    const id =
      e.currentTarget &&
      e.currentTarget.dataset &&
      e.currentTarget.dataset["clickmapid"];

    if (id) {
      const highlight = replayIframeDoc?.getElementById(id);
      if (highlight) {
        highlight.classList.add("is-highlight-active");
      }
    }
  };

  const clickmapHoverOut = (e) => {
    const id =
      e.currentTarget &&
      e.currentTarget.dataset &&
      e.currentTarget.dataset["clickmapid"];

    if (id) {
      const highlight = replayIframeDoc?.getElementById(id);
      if (highlight) {
        highlight.classList.remove("is-highlight-active");
      }
    }
  };

  return (
    <>
      <ul className="nav nav-pills">
        <li className="nav-item">
          <Button
            disabled={!initialTimestamp}
            active={activeTab === "details"}
            data-tabkey="details"
            size="small"
            onClick={handleClickTab}
            variant="plain"
          >
            Details
          </Button>
        </li>
        <li className="nav-item">
          <Button
            disabled={!initialTimestamp}
            active={activeTab === "events"}
            data-tabkey="events"
            size="small"
            onClick={handleClickTab}
            variant="plain"
          >
            Events
          </Button>
        </li>
        <li className="nav-item">
          <Button
            disabled={!initialTimestamp}
            active={activeTab === "analytics"}
            data-tabkey="analytics"
            size="small"
            onClick={handleClickTab}
            variant="plain"
          >
            Analytics
          </Button>
        </li>
        <li className="nav-item">
          <Button
            disabled={!initialTimestamp}
            active={activeTab === "notes"}
            data-tabkey="notes"
            size="small"
            onClick={handleClickTab}
            variant="plain"
          >
            Notes
          </Button>
        </li>
      </ul>
      {activeTab === "details" && (
        <>
          <div className="tab-container small">
            <p>
              You can explore the list of attributes for the session, but also
              use them as filters to see others with the same attributes
            </p>

            {pageviews && pageviews.length && (
              <>
                {/* AIzaSyBrP-DgPdTg79oVqSH1GqkyoVr3GKatFwo */}
                <iframe
                  title="Details Map"
                  key="details-map"
                  width="100%"
                  height="150"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAKXKbRaa5RqebWw65vi3DE_5Y-aEaIktw&q=${pageviews[0].city},${pageviews[0].countryCode}`}
                  allowFullScreen
                ></iframe>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Page URL"
                  id="replay-page-url"
                  data-key="pageUrl"
                  data-filter={JSON.stringify([
                    {
                      attribute: "PageURL",
                      operator: "is",
                      values: [pageviews[0].url],
                    },
                  ])}
                >
                  {pageviews[0].url}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Page Title"
                  id="replay-page-title"
                  disabled
                  data-filter={JSON.stringify({
                    attribute: "PageTitle",
                    operator: "is",
                    values: [pageviews[0].title],
                  })}
                >
                  {pageviews[0].title}
                </CardCheckbox>
                <CardCheckbox
                  disabled
                  onChange={handleClickDetailCard}
                  title="Time"
                  id="replay-timestamp"
                >
                  {moment(initialTimestamp).format("MMM D YYYY, HH:mm:ss")}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Browser"
                  id="replay-browser"
                  data-key="browser"
                  data-filter={JSON.stringify([
                    {
                      attribute: "Browser",
                      operator: "is",
                      values: [pageviews[0].browser],
                    },
                    {
                      attribute: "BrowserVersion",
                      operator: "is",
                      values: [String(pageviews[0].browserVersion)],
                    },
                  ])}
                >
                  {`${pageviews[0].browser} ${pageviews[0].browserVersion}`}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Screen Size"
                  id="replay-browser-size"
                  data-key={"browserSize"}
                  data-filter={JSON.stringify([
                    {
                      attribute: "BrowserWidth",
                      operator: "is",
                      values: [String(pageviews[0].browserWidth)],
                    },
                    {
                      attribute: "BrowserHeight",
                      operator: "is",
                      values: [String(pageviews[0].browserHeight)],
                    },
                  ])}
                >
                  {`${pageviews[0].browserWidth}x${pageviews[0].browserHeight}`}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="OS"
                  id="replay-os"
                  data-key="os"
                  data-filter={JSON.stringify([
                    {
                      attribute: "OS",
                      operator: "is",
                      values: [pageviews[0].os],
                    },
                    {
                      attribute: "OSVersion",
                      operator: "is",
                      values: [String(pageviews[0].osVersion)],
                    },
                  ])}
                >
                  {`${pageviews[0].os} ${pageviews[0].osVersion}`}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Device"
                  id="replay-device"
                  data-key="device"
                  data-filter={JSON.stringify([
                    {
                      attribute: "DeviceFamily",
                      operator: "is",
                      values: [pageviews[0].device],
                    },
                  ])}
                >
                  {`${pageviews[0].device}`}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Geolocation"
                  id="replay-geolocation"
                  data-key="geoLocation"
                  data-filter={JSON.stringify([
                    {
                      attribute: "City",
                      operator: "in",
                      values: [pageviews[0].city],
                    },
                    {
                      attribute: "Region",
                      operator: "in",
                      values: [pageviews[0].region],
                    },
                    {
                      attribute: "Country",
                      operator: "in",
                      values: [pageviews[0].countryCode],
                    },
                  ])}
                >
                  {`
                        ${capitalize(pageviews[0].city)},
                        ${pageviews[0].region.toUpperCase()},
                        ${countries.countries[pageviews[0].countryCode]}
                      `}
                </CardCheckbox>
                <CardCheckbox
                  onChange={handleClickDetailCard}
                  title="Traffic Source"
                  id="replay-traffic-source"
                  data-key="trafficSource"
                  data-filter={JSON.stringify([
                    {
                      attribute: "TrafficSource",
                      operator: "is",
                      values: [pageviews[0].source],
                    },
                    {
                      attribute: "TrafficMedium",
                      operator: "is",
                      values: [pageviews[0].medium],
                    },
                  ])}
                >
                  {`
                        ${pageviews[0].source}
                        ${
                          pageviews[0].medium !== null &&
                          pageviews[0].medium !== ""
                            ? " / " + pageviews[0].medium
                            : ""
                        } 
                      `}
                </CardCheckbox>
              </>
            )}
          </div>
          <div className="pt-2">
            <Button
              onClick={handleClickFindSimilar}
              variant="primary"
              size="small"
              style={{ width: "100%" }}
            >
              Find Similar Users
              {!!Object.keys(selectedFilters).length &&
                ` (${Object.keys(selectedFilters).length})`}
            </Button>
          </div>
        </>
      )}
      {activeTab === "events" && (
        <div className="tab-container small" id="replay-events-tab-container">
          <div className="d-flex mb-2 filter-header">
            <Button
              variant="plain"
              size="small"
              className="mr-auto"
              onClick={handleClickShowEventFilters}
            >
              <i className="fas fa-filter"></i>
            </Button>
            <span className="px-2 py-2 mr-1 rounded small">
              {eventsCount} events
            </span>
          </div>
          <table className="event-list table table-striped table-borderless">
            <tbody>
              {eventComponents.length > 0 &&
                eventComponents.map((event, index) => {
                  return event;
                })}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === "analytics" && (
        <>
          <div className="tab-container small">
            {!!isClickmapDataLoading && !isPageClickmapDataLoaded && (
              <LoaderWithText
                className="small my-4"
                text={`Loading ${"\n"} Analytics Data`}
              />
            )}
            {!isClickmapDataLoading && !!isPageClickmapDataLoaded && (
              <>
                <Collapsable
                  collapsed={analyticsMode !== "elements"}
                  className="mb-2"
                  title="Inspect Elements"
                  id="analytics-elements"
                  handleToggle={handleChangeAnalysisMode}
                  triggerOptions={{
                    "data-mode": "elements",
                  }}
                >
                  {!hoveredData && (
                    <p className="text-center text-gray-400 my-4">
                      Hover on the page elements to
                      <br />
                      view stats and create behavioral
                      <br />
                      segments
                    </p>
                  )}
                  {!!hoveredData && <ElementStats data={hoveredData} />}
                </Collapsable>
                <Collapsable
                  collapsed={analyticsMode !== "clickmap"}
                  className="mb-2"
                  title={`Clickmap`}
                  id="analytics-clickmap"
                  handleToggle={handleChangeAnalysisMode}
                  triggerOptions={{
                    "data-mode": "clickmap",
                  }}
                >
                  {!!isScrollmapDataLoading && (
                    <LoaderWithText
                      className="small my-4"
                      text={`Loading ${"\n"} Analytics Data`}
                    />
                  )}
                  {isPageScrollmapDataLoaded && (
                    <>
                      <div className="d-flex align-items-center align mb-3">
                        <Dropdown
                          className="mr-auto"
                          id="clickmap-mode"
                          icon={() => <BiCategoryAlt size={14} />}
                          defaultSelected={analyticsClickmapMode}
                          handleChange={handleChangeClickmapMode}
                          items={[
                            {
                              label: "All Elements",
                              value: "all",
                            },
                            {
                              label: "Links",
                              value: "links",
                            },
                            {
                              label: "Last Element Click",
                              value: "lastElementClick",
                            },
                          ]}
                        />
                        <span className="text-charcoal">
                          {kSeparatorDecimal(allClickmapData)} items
                        </span>
                      </div>
                      <div className="d-flex mb-3">
                        <SegmentedButton
                          className="w-100"
                          segments={filterItems(parseInt(allClickmapData))}
                          value={clickmapDataFilter.value}
                          onChange={handleChangeClickmapDataFilter}
                        />
                      </div>
                      {!!Object.keys(clickmapLinks).length &&
                        analyticsClickmapMode !== "lastElementClick" &&
                        Object.keys(clickmapLinks).map((key, index) => {
                          const link = clickmapLinks[key];
                          const element = querySelector(
                            replayIframeDoc,
                            formatCss(link.data.css)
                          );
                          // Prevent injecting massive text in the DOM
                          const contentTextShown = element?.innerText
                            ? ellipsize(element.innerText, 150)
                            : "";
                          return (
                            <ClickmapCard
                              onClick={handleClickShowFilterModal}
                              onMouseEnter={clickmapHover}
                              onMouseLeave={clickmapHoverOut}
                              data-clickmapid={key}
                              key={index}
                              index={index + 1}
                              title={
                                <>
                                  <div
                                    className="text-ellipsis sm card-title-element"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={contentTextShown}
                                  >
                                    <strong>
                                      <span>{`<${link.data.tag}> Element`}</span>
                                      {!!contentTextShown && (
                                        <span>{` - "${contentTextShown}"`}</span>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className="card-title-stats"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={`${kSeparatorDecimal(
                                      link.data.clc
                                    )} element clicks / ${kSeparatorDecimal(
                                      totalPageviews
                                    )} pageviews`}
                                  >
                                    <FaRegHandPointer color="#4a4afa" />
                                    <span>Clicks: </span>

                                    <strong>
                                      {kSeparatorDecimal(link.data.clc, 0, 0)}
                                      {` (${link.data.clickPct})`}
                                    </strong>
                                  </div>
                                  {link.data.con !== 0 && (
                                    <div
                                      className="card-title-stats"
                                      data-tooltip-id="replay-viewer-tooltip"
                                      data-tooltip-content={`${kSeparatorDecimal(
                                        link.data.con
                                      )} converted session / ${kSeparatorDecimal(
                                        link.data.clcSess
                                      )} clicked sessions`}
                                    >
                                      <LuGoal color="#05CF9B" />
                                      <span>Conversions: </span>
                                      <strong>
                                        {kSeparatorDecimal(link.data.con)}
                                        {` (${link.data.conPct})`}
                                      </strong>
                                    </div>
                                  )}
                                  {link.data.rev !== 0 && (
                                    <div
                                      className="card-title-stats"
                                      data-tooltip-id="replay-viewer-tooltip"
                                      data-tooltip-content={`$${kSeparatorDecimal(
                                        link.data.rev,
                                        2,
                                        2
                                      )} total revenue and $${kSeparatorDecimal(
                                        link.data.revAvg,
                                        2,
                                        2
                                      )} average revenue from converted sessions`}
                                    >
                                      <LuCircleDollarSign color="#05CF9B" />
                                      <span>Revenue: </span>
                                      <strong>
                                        {`$${kSeparatorDecimal(
                                          link.data.rev,
                                          2,
                                          2
                                        )}`}
                                        {` (Avg $${kSeparatorDecimal(
                                          link.data.revAvg,
                                          2,
                                          2
                                        )})`}
                                      </strong>
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <ProgressBar progress={link.data.clickPct} />
                            </ClickmapCard>
                          );
                        })}

                      {!!Object.keys(clickmapLinks).length &&
                        analyticsClickmapMode === "lastElementClick" &&
                        Object.keys(clickmapLinks).map((key, index) => {
                          const link = clickmapLinks[key];
                          const element = querySelector(
                            replayIframeDoc,
                            formatCss(link.data.css)
                          );
                          // Prevent injecting massive text in the DOM
                          const contentTextShown = element?.innerText
                            ? ellipsize(element.innerText, 150)
                            : "";
                          return (
                            <ClickmapCard
                              onClick={handleClickShowFilterModal}
                              onMouseEnter={clickmapHover}
                              onMouseLeave={clickmapHoverOut}
                              data-clickmapid={key}
                              key={index}
                              index={index + 1}
                              title={
                                <>
                                  <div
                                    className="text-ellipsis sm card-title-element"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={contentTextShown}
                                  >
                                    <strong>
                                      <span>{`<${link.data.tag}> Element`}</span>
                                      {!!contentTextShown && (
                                        <span>{` - "${contentTextShown}"`}</span>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className="card-title-stats"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={`${kSeparatorDecimal(
                                      link.data.lec
                                    )} element clicks / ${kSeparatorDecimal(
                                      totalPageviews
                                    )} pageviews`}
                                  >
                                    <FaRegHandPointer color="#4a4afa" />
                                    <span>Clicks: </span>

                                    <strong>
                                      {kSeparatorDecimal(link.data.lec)}
                                      {` (${link.data.lecPct})`}
                                    </strong>
                                  </div>
                                  {link.data.con !== 0 && (
                                    <div
                                      className="card-title-stats"
                                      data-tooltip-id="replay-viewer-tooltip"
                                      data-tooltip-content={`${kSeparatorDecimal(
                                        link.data.con
                                      )} converted session / ${kSeparatorDecimal(
                                        link.data.clcSess
                                      )} clicked sessions`}
                                    >
                                      <LuGoal color="#05CF9B" />
                                      <span>Conversions: </span>
                                      <strong>
                                        {kSeparatorDecimal(link.data.con)}
                                        {` (${link.data.conPct})`}
                                      </strong>
                                    </div>
                                  )}
                                  {link.data.rev !== 0 && (
                                    <div
                                      className="card-title-stats"
                                      data-tooltip-id="replay-viewer-tooltip"
                                      data-tooltip-content={`$${kSeparatorDecimal(
                                        link.data.rev,
                                        2,
                                        2
                                      )} total revenue and $${kSeparatorDecimal(
                                        link.data.revAvg,
                                        2,
                                        2
                                      )} average revenue from converted sessions`}
                                    >
                                      <LuCircleDollarSign color="#05CF9B" />
                                      <span>Revenue: </span>
                                      <strong>
                                        {`$${kSeparatorDecimal(
                                          link.data.rev,
                                          2,
                                          2
                                        )}`}
                                        {` (${`Avg $${kSeparatorDecimal(
                                          link.data.revAvg,
                                          2,
                                          2
                                        )}`})`}
                                      </strong>
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <ProgressBar progress={link.data.lecPct} />
                            </ClickmapCard>
                          );
                        })}
                    </>
                  )}
                </Collapsable>
                <Collapsable
                  collapsed={analyticsMode !== "frictionmap"}
                  className="mb-2"
                  title="Friction Map"
                  id="analytics-frictionmap"
                  handleToggle={handleChangeAnalysisMode}
                  triggerOptions={{
                    "data-mode": "frictionmap",
                  }}
                >
                  {!!isScrollmapDataLoading && (
                    <LoaderWithText
                      className="small my-4"
                      text={`Loading ${"\n"} Analytics Data`}
                    />
                  )}
                  {isPageScrollmapDataLoaded && (
                    <>
                      <div className="d-flex align-items-center mb-2">
                        <Dropdown
                          className="mr-auto"
                          id="clickmap-mode"
                          icon={() => <BiCategoryAlt size={14} />}
                          defaultSelected={analyticsFrictionmapMode}
                          handleChange={handleChangeFricitionmapMode}
                          items={[
                            {
                              label: "Rage Clicks",
                              value: "rageClicks",
                            },
                          ]}
                        />
                        <div>
                          {Object.keys(clickmapLinks).length}
                          {` items`}
                        </div>
                      </div>
                      {!!Object.keys(clickmapLinks).length &&
                        analyticsFrictionmapMode === "rageClicks" &&
                        Object.keys(clickmapLinks).map((key, index) => {
                          const link = clickmapLinks[key];
                          const element = querySelector(
                            replayIframeDoc,
                            formatCss(link.data.css)
                          );
                          // Prevent injecting massive text in the DOM
                          const contentTextShown = element?.innerText
                            ? ellipsize(element.innerText, 150)
                            : "";
                          return (
                            <ClickmapCard
                              onClick={handleClickShowFilterModal}
                              onMouseEnter={clickmapHover}
                              onMouseLeave={clickmapHoverOut}
                              data-clickmapid={key}
                              key={index}
                              index={index + 1}
                              title={
                                <>
                                  <div
                                    className="text-ellipsis sm card-title-element"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={contentTextShown}
                                  >
                                    <strong>
                                      <span>{`<${link.data.tag}> Element`}</span>
                                      {!!contentTextShown && (
                                        <span>{` - "${contentTextShown}"`}</span>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className="card-title-stats"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={`${kSeparatorDecimal(
                                      link.data.rgc
                                    )} element rage clicks / ${kSeparatorDecimal(
                                      totalPageviews
                                    )} pageviews`}
                                  >
                                    <FaRegAngry color="#FD9041" />
                                    <span>Rage Clicks: </span>
                                    <strong>
                                      {kSeparatorDecimal(link.data.rgc)}{" "}
                                      {` (${link.data.rgcPct})`}
                                    </strong>
                                  </div>
                                </>
                              }
                            >
                              <ProgressBar progress={link.data.rgcPct} />
                            </ClickmapCard>
                          );
                        })}
                    </>
                  )}
                </Collapsable>
                <Collapsable
                  collapsed={analyticsMode !== "form-analysis"}
                  className="mb-2"
                  title={`Visual Form Analysis`}
                  id="analytics-form-analysis"
                  handleToggle={handleChangeAnalysisMode}
                  triggerOptions={{
                    "data-mode": "form-analysis",
                  }}
                >
                  {isPageScrollmapDataLoaded && (
                    <>
                      <div className="d-flex align-items-center mb-2">
                        <Dropdown
                          className="mr-auto"
                          id="clickmap-mode"
                          icon={() => <BiCategoryAlt size={14} />}
                          defaultSelected={analyticsFormAnalysisMode}
                          handleChange={handleChangeFormAnalysisMode}
                          items={[
                            {
                              label: "Last Clicks",
                              value: "lastClicks",
                            },
                            {
                              label: "Most Clicks",
                              value: "mostClicks",
                            },
                          ]}
                        />
                        <div>
                          {Object.keys(clickmapLinks).length}
                          {` items`}
                        </div>
                      </div>
                      {!!Object.keys(clickmapLinks).length &&
                        analyticsFormAnalysisMode === "lastClicks" &&
                        Object.keys(clickmapLinks).map((key, index) => {
                          const link = clickmapLinks[key];
                          const element = querySelector(
                            replayIframeDoc,
                            formatCss(link.data.css)
                          );
                          // Prevent injecting massive text in the DOM
                          const contentTextShown = element?.innerText
                            ? ellipsize(element.innerText, 150)
                            : "";
                          return (
                            <ClickmapCard
                              onClick={handleClickShowFilterModal}
                              onMouseEnter={clickmapHover}
                              onMouseLeave={clickmapHoverOut}
                              data-clickmapid={key}
                              key={index}
                              index={index + 1}
                              title={
                                <>
                                  <div
                                    className="text-ellipsis sm card-title-element"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={contentTextShown}
                                  >
                                    <strong>
                                      <span>{`<${link.data.tag}> Element`}</span>
                                      {!!contentTextShown && (
                                        <span>{` - "${contentTextShown}"`}</span>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className="card-title-stats"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={`${kSeparatorDecimal(
                                      link.data.lfc
                                    )} element last clicks / ${kSeparatorDecimal(
                                      totalPageviews
                                    )} pageviews`}
                                  >
                                    <PiCursorClickBold color="#4a4afa" />
                                    <span>Last Clicks: </span>
                                    <strong>
                                      {kSeparatorDecimal(link.data.lfc)}
                                      {` (${link.data.lfcPct})`}
                                    </strong>
                                  </div>
                                </>
                              }
                            >
                              <ProgressBar progress={link.data.lfcPct} />
                            </ClickmapCard>
                          );
                        })}
                      {!!Object.keys(clickmapLinks).length &&
                        analyticsFormAnalysisMode === "mostClicks" &&
                        Object.keys(clickmapLinks).map((key, index) => {
                          const link = clickmapLinks[key];
                          const element = querySelector(
                            replayIframeDoc,
                            formatCss(link.data.css)
                          );
                          // Prevent injecting massive text in the DOM
                          const contentTextShown = element?.innerText
                            ? ellipsize(element.innerText, 150)
                            : "";
                          return (
                            <ClickmapCard
                              onClick={handleClickShowFilterModal}
                              onMouseEnter={clickmapHover}
                              onMouseLeave={clickmapHoverOut}
                              data-clickmapid={key}
                              key={index}
                              index={index + 1}
                              title={
                                <>
                                  <div
                                    className="text-ellipsis sm card-title-element"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={contentTextShown}
                                  >
                                    <strong>
                                      <span>{`<${link.data.tag}> Element`}</span>
                                      {!!contentTextShown && (
                                        <span>{` - "${contentTextShown}"`}</span>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className="card-title-stats"
                                    data-tooltip-id="replay-viewer-tooltip"
                                    data-tooltip-content={`${kSeparatorDecimal(
                                      link.data.clc
                                    )} element clicks / ${kSeparatorDecimal(
                                      totalPageviews
                                    )} pageviews`}
                                  >
                                    <FaRegHandPointer color="#4a4afa" />
                                    <span>Clicks: </span>
                                    <strong>
                                      {kSeparatorDecimal(link.data.clc)}
                                      {` (${link.data.clickPct})`}
                                    </strong>
                                  </div>
                                </>
                              }
                            >
                              <ProgressBar progress={link.data.clickPct} />
                            </ClickmapCard>
                          );
                        })}
                    </>
                  )}
                </Collapsable>
                <Collapsable
                  collapsed={analyticsMode !== "scrollmap"}
                  className="mb-2"
                  title={`Scrolling Heatmap`}
                  id="analytics-scrollmap"
                  handleToggle={handleChangeAnalysisMode}
                  triggerOptions={{
                    "data-mode": "scrollmap",
                  }}
                >
                  {!!isScrollmapDataLoading && (
                    <LoaderWithText
                      className="small my-4"
                      text={`Loading ${"\n"} Analytics Data`}
                    />
                  )}
                  {isPageScrollmapDataLoaded && (
                    <div className="clickmap-stats">
                      <h4>Element Stats</h4>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td className="stat-name">Mouse Position</td>
                            <td className="stat-value">
                              {`X:${mouseData.x} Y:${Math.round(mouseData.y)}`}
                            </td>
                          </tr>
                          <tr>
                            <td className="stat-name">Reach Pageviews</td>
                            <td className="stat-value">
                              {mouseData.pageViews}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </Collapsable>
              </>
            )}
          </div>

          <div>
            <AlertBox
              noDataAlert={noDataAlert}
              setNoDataAlert={setNoDataAlert}
            />
          </div>

          {!!pageClickmapData && (
            <div className="pt-2">
              <hr />
              <p className="text-center text-gray-500 small">
                <i className="fa fa-info-circle"></i> Analytics data is based on
                <strong>
                  {` ${
                    pageClickmapData?.totals?.pageViews
                      ? pageClickmapData?.totals?.pageViews
                      : 0
                  }`}{" "}
                  pageviews
                </strong>
              </p>
              <Button
                onClick={handleClickFiltersApplied}
                variant="primary"
                size="small"
                style={{ width: "100%" }}
              >
                Segments & Filters Applied
                {!!Object.keys(selectedFilters).length &&
                  ` (${Object.keys(selectedFilters).length})`}
              </Button>
            </div>
          )}
        </>
      )}
      {activeTab === "notes" && (
        <div className="tab-container small">
          {!!isNotesLoading && (
            <LoaderWithText
              className="small my-4"
              text={`Loading ${"\n"} Notes`}
            />
          )}
          {isNotesLoaded &&
            notes.all.map((key, index) => {
              const note = notes.byId[key];
              const noteUrl = `${window.location.protocol}//${window.location.host}/note/${note.profileId}/${key}`;
              const noteId =
                props.location.state?.noteData?.id || // "View Replay" from the note
                props.location.state?.replayOptions?.noteId; // "Copy link" from the note
              return (
                <NoteCard
                  compact={true}
                  key={key}
                  id={key}
                  index={index}
                  profileId={profileId}
                  content={notes.byId[key].description}
                  noteUrl={noteUrl}
                  noteTime={notes.byId[key].noteTime}
                  creationTime={notes.byId[key].creationTime}
                  tags={notes.byId[key].tags}
                  profileUsers={profileUsers}
                  photoUrl={
                    profileUsers.byId[notes.byId[key].ownerId]
                      ? profileUsers.byId[notes.byId[key].ownerId].photoURL
                      : null
                  }
                  authorName={
                    profileUsers.byId[notes.byId[key].ownerId]
                      ? profileUsers.byId[notes.byId[key].ownerId].name
                      : ""
                  }
                  analyticsMode={notes.byId[key]?.analyticsMode || "moment"}
                  startDate={notes.byId[key]?.startDate}
                  endDate={notes.byId[key]?.endDate}
                  filter={notes.byId[key]?.filter}
                  segments={notes.byId[key]?.segments}
                  shared={notes.byId[key]?.shared}
                  version={notes.byId[key]?.version || "0.0"}
                  handleClickEditNote={handleClickEditNote}
                  handleClickDeleteNote={handleClickDeleteNote}
                  handleClickPlayNote={handleClickPlayNote}
                  handleClickCopy={() => {
                    dispatch({
                      type: ADD_TOAST,
                      messageType: "success",
                      messageText: `Note url copied to clipboard`,
                    });
                  }}
                  showOwnerPrivateNotes={
                    authUser.user.uid === notes.byId[key].ownerId
                  }
                  showOwnerControls={
                    authUser.user.uid === notes.byId[key].ownerId
                  }
                  isActive={noteId === key}
                />
              );
            })}
          {isNotesLoaded && !notes.all.length && (
            <>
              <p className="text-center">
                Your saved views including Moments, Click Maps, Form Analysis
                and Scrolling Heatmaps are listed here, see{" "}
                <a
                  target="_blank"
                  href="https://support.insightech.com/article/1trfu8w2ip-sharing-replays-insights-and-notes-with-the-team"
                >
                  help doc on notes
                </a>{" "}
                for details.
              </p>
            </>
          )}
        </div>
      )}
      <ReactTooltip id="replay-viewer-tooltip" className={"tooltip-md"} />
    </>
  );
};

export default ReplaySidePanelTabs;
