import React from 'react';
import reducer from './index';

const initialState = {
  toastMessages: [],
  toastPaused: false,
  isConfirmModalVisible: false,
  isConfirmBusy: false,
  confirmModalConfig: {
    title: 'Confirm Action',
    text: null,
    confirmText: null,
    confirmAction: ()=>{}
  },
  confirmModalAction: ()=>{},
  profileError: null
}

const ReducerContext = React.createContext(initialState);

function ReducerProvider(props){
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      {props.children}
    </ReducerContext.Provider>
  )
}

export {ReducerContext, ReducerProvider};
