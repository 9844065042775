import React, { Fragment } from "react";
import {
  parseUrl,
  parseUrlWithNoProtocol,
  kSeparatorDecimal,
} from "../../../utils";
import useTableFilter from "./useTableFilter";
import { Widgets } from "../../../constants/widgets";
import { BsFunnelFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Table = ({ data, widget }) => {
  const { handleCellClick } = useTableFilter();
  const isAllowCrossFilter = widget !== Widgets.Trend;

  return (
    <Fragment>
      <table
        className="table table-hover dashboard-table"
        id={`table-${widget}`}
      >
        <thead className="fixed-table-header">
          <tr>
            {data[0]?.map((item, index) => {
              return (
                <th key={index}>
                  <strong>{item}</strong>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, index) => {
            return (
              <tr key={index} className="table-row">
                {row?.map((item, index) => {
                  if (index === 0) {
                    // Column of the value
                    let itemLabel = item;

                    if (widget === Widgets.TopPage) {
                      const url = parseUrl(item);
                      if (url) {
                        itemLabel = `${url.pathname}${url.search}${url.hash}`;
                      }
                    } else if (widget === Widgets.TopLandingPage) {
                      const url = parseUrlWithNoProtocol(item);
                      if (url) {
                        itemLabel = `${url.pathname}`;
                      }
                    }

                    return (
                      <td key={index}>
                        {!!widget ? (
                          <>
                            {!!isAllowCrossFilter && (
                              <button
                                className="filter-icon-container"
                                value={item}
                                onClick={() => handleCellClick(item, widget)}
                                data-tooltip-id="table-tooltip"
                                data-tooltip-content="Click to filter"
                              >
                                <BsFunnelFill className="filter-icon" />
                              </button>
                            )}

                            <span
                              className={`cell-ellipsis ${
                                widget === Widgets.TopPage ? "lg" : ""
                              }${
                                widget === Widgets.TopLandingPage ? "xlg" : ""
                              }`}
                              data-tooltip-id="table-tooltip"
                              data-tooltip-content={item}
                            >
                              {itemLabel}
                            </span>
                          </>
                        ) : (
                          item
                        )}
                      </td>
                    );
                  } else {
                    // Other columns
                    return <td key={index}>{kSeparatorDecimal(item, 0)}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactTooltip id="table-tooltip" className="tooltip-lg" />
    </Fragment>
  );
};

export default Table;
