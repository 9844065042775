import React, { useContext } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import AppMenu from "../menus/AppMenu";
import ProfileMenu from "../menus/ProfileMenu";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UserDataContext } from "../UserData";

const SideBarNav = (props) => {
  const { profileId } = props;

  const { isSidebarCollapsed, setIsSidebarCollapsed } =
    useContext(UserDataContext);

  const handleSidebarToggle = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <aside className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
      {!profileId ? <AppMenu /> : <ProfileMenu profileId={profileId} />}
      <footer className="sidebar-footer">
        <span
          className="footer-icon"
          onClick={handleSidebarToggle}
          data-tooltip-id="toggle-tooltip"
          data-tooltip-content="Toggle Navigation Menu"
        >
          {isSidebarCollapsed ? <MdNavigateNext /> : <MdNavigateBefore />}
        </span>
      </footer>
      <ReactTooltip id="toggle-tooltip" className="tooltip-sm" place="right" />
    </aside>
  );
};

SideBarNav.propTypes = {
  profileId: PropTypes.string,
};
SideBarNav.defaultProps = {
  profileId: undefined,
};

export default SideBarNav;
