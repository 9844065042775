import React, { useState, useRef, useEffect } from "react";
import { checkTruncation } from "../../../../utils";
import PropTypes from "prop-types";
import Button from "../Button";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import useClickOutside from "../../../../hooks/useClickOutside";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";

const DropdownButton = ({
  children,
  id,
  label,
  icon,
  size = "chip",
  color = "plain",
  disabled = false,
  className = "",
  onClick,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const dropdownRef = useRef(null);
  const textRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  // Check if the text is truncated
  useEffect(() => {
    const handleTruncationCheck = () => {
      if (textRef.current) {
        setIsTextTruncated(checkTruncation(textRef.current));
      }
    };

    handleTruncationCheck();
  }, [label]);

  const handleClick = (e) => {
    if (!disabled) {
      setIsOpen(!isOpen);
      onClick?.(e);
    }
  };

  return (
    <div
      className={`ui-dropdown-container ${className}`}
      id={id}
      ref={dropdownRef}
    >
      <Button
        color={color}
        size={size}
        icon={icon}
        disabled={disabled}
        onClick={handleClick}
        dropdownArrow={isOpen ? FaChevronUp : FaChevronDown}
        data-tooltip-id="dropdown-button-tooltip"
        data-tooltip-content={isTextTruncated ? label : ""}
        {...rest}
      >
        <span ref={textRef}>{label}</span>
      </Button>

      {isOpen && <div className="ui-dropdown-menu">{children}</div>}
      <ReactTooltip
        id="dropdown-button-tooltip"
        className="tooltip-md"
        place="bottom"
        style={{ zIndex: "1000" }}
      />
    </div>
  );
};

DropdownButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.elementType,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropdownButton;
