import React from "react";
import PropTypes from "prop-types";
import { Button, Drawer } from "./../../share/InsightUI";
import { SegmentsContext } from "./../../../context/Segments";
import UrlFilterPanel from "./../../app/FilterPanel/UrlFilterPanel";
import SegmentsDropdown from "./../../app/SegmentsDropdown";
import SegmentButton from "./../../app/SegmentButton";
import DatePicker from "./../../app/DatePicker";
import "./style.scss";

function FilterDrawer(props) {
  const {
    isVisible,
    handleClose,
    segmentsWithChecked,
    setSegmentsWithChecked,
    filterConditionsInput,
    setFilterConditionsInput,
    selectedDateRange,
    setSelectedDateRange,
    selectedDates,
    setSelectedDates,
    handleRefreshData,
    width,
    isFetchingData,
  } = props;

  const { segments, selectedSegments, setSelectedSegments } =
    React.useContext(SegmentsContext);

  const handleUnselectSegment = (id) => {
    const updatedSegments = { ...selectedSegments };
    delete updatedSegments[id];
    setSelectedSegments({
      ...updatedSegments,
    });
  };

  return (
    <Drawer
      className="filter-drawer small"
      title="Filter By"
      isVisible={isVisible}
      handleClose={handleClose}
      disableClose={isFetchingData}
      width={width}
    >
      <div className="ui-form">
        <p className="mb-1">Date Range</p>
        <div className="filters-section bg-gray-100 mb-4">
          <DatePicker
            customSelectedDates={selectedDates}
            customSetSelectedDates={setSelectedDates}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            dropdownAlignment="left"
          />
        </div>
        <p className="mb-1">Segments</p>
        <div className="filters-section bg-gray-100 mb-4">
          <SegmentsDropdown
            setReloadApi={() => {}}
            segments={segments}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            segmentsWithChecked={segmentsWithChecked}
            setSegmentsWithChecked={setSegmentsWithChecked}
            showEditSegments={false}
          />
          {Object.keys(selectedSegments).map((key) => {
            const segment = segments[key];
            return segment ? (
              <SegmentButton
                className="btn-success ml-2"
                handleClick={() => {}}
                handleClickDelete={handleUnselectSegment}
                key={segment.id}
                id={segment.id}
                label={segment.name}
              />
            ) : null;
          })}
        </div>

        <p className="mb-1">Filters</p>
        <div className="filters-section bg-gray-100">
          <UrlFilterPanel
            filterConditionsInput={filterConditionsInput}
            setFilterConditionsInput={setFilterConditionsInput}
          />
        </div>

        <div className="drawer-buttons">
          <Button
            onClick={handleRefreshData}
            disabled={isFetchingData}
            style={{ minWidth: 120 }}
            type="button"
            size="small"
            variant="primary"
          >
            {!!isFetchingData ? (
              <>
                <span className="fa fa-spinner spin"></span>
                {` Refreshing Data`}
              </>
            ) : (
              `Refresh Data`
            )}
          </Button>
          <Button
            disabled={isFetchingData}
            type="button"
            size="small"
            className="ml-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

FilterDrawer.propTypes = {
  isVisible: PropTypes.bool,
  isFetchingData: PropTypes.bool,
};
FilterDrawer.defaultProps = {};

export default FilterDrawer;
