import React, { useContext, useState, useEffect } from "react";
import withProfileAdminCheck from "../../../../components/hoc/withProfileAdminCheck";
import Alert from "./../../../../components/share/Alert";
import LoaderWithText from "./../../../../components/share/LoaderWithText";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import TimezoneField from "./../../../../components/share/fields/TimezoneField";
import TextField from "./../../../../components/share/fields/TextField";
import TextareaField from "./../../../../components/share/fields/TextareaField";
import SubmitButtons from "./../../../../components/share/fields/SubmitButtons";
import { fetchProfile } from "../../../../lib/firebase/profile";
import api from "./../../../../api";

const UpdateProfile = (props) => {
  const { authUser } = useContext(AuthContext);
  const profileId = props.match.params.profileId;

  const [timezone, setTimezone] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [domains, setDomains] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFormBusy, setIsFormBusy] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleClickUpdate = () => {
    let domainsArr = domains.split("\n");
    // Must match with the backend validation
    // Allow "ab2.com.au", "example.com", "sub1.sub2.exam-ple.com"
    // Do not allow "localhost"
    const domainRegex =
      /^([\p{L}\p{M}\p{N}_%+-]+\.)+[\p{L}\p{M}\p{N}]+$/u;
    for (let i = 0, len = domainsArr.length; i < len; i++) {
      if (!domainRegex.test(domainsArr[i])) {
        setAlert({
          show: true,
          type: "danger",
          message: ` \`${domainsArr[i]}\` is not a valid domain name`,
          count: alert.count + 1,
        });
        return;
      }
    }
    setIsProcessing(true);
    api
      .updateProfile(authUser, { profileId, profileName, domains: domainsArr })
      .then((res) => {
        if (res.data.status === 200 && res.data.result === "success") {
          setIsProcessing(false);
          setAlert({
            show: true,
            type: "success",
            message: "Profile settings have been updated",
            count: alert.count + 1,
          });
        }
      });
  };

  React.useEffect(() => {
    setIsFormBusy(true);
    setIsLoading(true);
    fetchProfile({ profileId }).then((res) => {
      const data = res.data.data;
      setProfileName(data.name);
      setTimezone(data.timezone);
      setDomains(data.domains.join("\n"));
      setIsFormBusy(false);
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              {isLoading && (
                <LoaderWithText className="my-4" text="Loading Profile Data" />
              )}
              {!isLoading && (
                <>
                  <TextField
                    handleChange={(e) => {
                      setProfileName(e.target.value);
                    }}
                    id="profile-name"
                    label="Profile Name"
                    placeholder="e.g. Production websites"
                    text={profileName}
                    helpText="Profile name is to identify the websites tracked in the profile."
                  />
                  <TimezoneField
                    textOnly
                    setTimezone={setTimezone}
                    defaultTimezone={timezone}
                    helpText="Set your data processing timezone. The setting cannot be changed after it's set."
                  ></TimezoneField>
                  <TextareaField
                    text={domains}
                    placeholder="e.g. yourcompany.com"
                    label="Domains"
                    id="domains"
                    helpText="List all the top domains of your website journey, including your payment processors (e.g. paypal.com). One line per domain."
                    handleChange={(e) => {
                      setDomains(e.target.value);
                    }}
                  />
                  <SubmitButtons
                    disabled={isFormBusy}
                    processing={isProcessing}
                    backButtonTo="/"
                    handleClick={handleClickUpdate}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withProfileAdminCheck(UpdateProfile);
