import React from "react";

const ReplaysDynamic = ({ color = "#4A4AFA", ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_48_1335"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_48_1335)">
      <path
        d="M8.67439 13.1369V6.90023L13.5689 10.0186L8.67439 13.1369ZM10.0093 18C8.66203 18 7.4044 17.6783 6.23638 17.0348C5.06837 16.3913 4.1012 15.5128 3.33488 14.3991V16.4408H2V12.2088H6.22711V13.5452H4.39166C5.02202 14.5104 5.8285 15.2715 6.81112 15.8283C7.79374 16.3851 8.85979 16.6636 10.0093 16.6636C11.443 16.6636 12.7346 16.2459 13.8841 15.4107C15.0336 14.5754 15.8494 13.4834 16.3314 12.1346L17.6292 12.4316C17.0977 14.1021 16.1336 15.4478 14.737 16.4687C13.3403 17.4896 11.7644 18 10.0093 18ZM2.03708 9.31323C2.11124 8.48415 2.30282 7.686 2.61182 6.91879C2.92082 6.15159 3.35342 5.45244 3.90962 4.82135L4.85516 5.76798C4.44728 6.2877 4.11356 6.84455 3.854 7.43852C3.59444 8.03248 3.43376 8.65739 3.37196 9.31323H2.03708ZM5.78216 4.82135L4.85516 3.87471C5.48552 3.31787 6.18385 2.88167 6.95017 2.56613C7.71649 2.25058 8.51371 2.06187 9.34183 2V3.33643C8.68675 3.41067 8.05948 3.57773 7.46002 3.83759C6.86056 4.09745 6.30127 4.42537 5.78216 4.82135ZM14.2364 4.82135C13.7173 4.41299 13.158 4.08198 12.5585 3.82831C11.9591 3.57463 11.3318 3.41067 10.6767 3.33643V2C11.5172 2.06187 12.3206 2.24749 13.0869 2.55684C13.8532 2.8662 14.5516 3.2993 15.1819 3.85615L14.2364 4.82135ZM16.6466 9.31323C16.5724 8.65739 16.4087 8.03248 16.1553 7.43852C15.9019 6.84455 15.5713 6.2877 15.1634 5.76798L16.1275 4.80278C16.6713 5.43387 17.1039 6.13302 17.4253 6.90023C17.7466 7.66744 17.9382 8.47177 18 9.31323H16.6466Z"
        fill={color}
      />
    </g>
  </svg>
);

export default ReplaysDynamic;
