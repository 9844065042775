import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "../../InsightechUI";
import InsightechIcon from "../../InsightechIcon";
import useClickOutside from "../../../../hooks/useClickOutside";

function Dropdown(props) {
  const {
    align,
    className,
    toggleButton,
    id,
    items,
    defaultSelected,
    handleChange,
    selectedItem,
    icon,
  } = props;

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setIsVisible(false));

  const [selected, setSelected] = useState({
    label: "Dropdown",
    value: "",
  });
  const [isVisible, setIsVisible] = useState(false);

  let alignClass = "";

  switch (align) {
    case "right":
      alignClass = "dropdown-menu-right";
      break;
    default:
      break;
  }

  const DropdownArrowIcon = () => (
    <InsightechIcon name={isVisible ? "ChevronUp" : "ChevronDown"} />
  );

  const handleClickItem = (e) => {
    const target = e.target;
    const value = target.dataset["value"];
    const label = target.innerText;
    setSelected({
      value,
      label,
    });
    handleChange({
      value,
      label,
    });
    setIsVisible(false);
  };

  // Keep single source of truth of the selected item
  useEffect(() => {
    if (selectedItem) {
      setSelected({ label: selectedItem.label, value: selectedItem.value });
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!defaultSelected && items && items.length) {
      setSelected({
        label: items[0].label,
        value: items[0].value,
      });
    }
    if (defaultSelected && items && items.length) {
      let defaultItem;
      items.forEach((item) => {
        if (item.value === defaultSelected) {
          defaultItem = item;
        }
      });
      setSelected({
        label: defaultItem?.label,
        value: defaultItem?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      {toggleButton}
      {!toggleButton && (
        <Button
          size="flag"
          color="outlined-grey"
          dropdownArrow={DropdownArrowIcon}
          id={id}
          aria-expanded={isVisible}
          onClick={handleClick}
          icon={icon}
        >
          {selected.label}
        </Button>
      )}
      <div
        className={`dropdown-menu shadow ${alignClass} ${
          isVisible ? "show" : ""
        }`}
        aria-labelledby={id}
      >
        {!items && props.children}
        {!!items &&
          items.map((item, index) => {
            return (
              <div
                key={index}
                onClick={handleClickItem}
                data-value={item.value}
                className="dropdown-item"
              >
                {item.label}
              </div>
            );
          })}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  toggleButton: PropTypes.node,
  items: PropTypes.array,
  handleChange: PropTypes.func,
};
Dropdown.defaultProps = {
  handleChange: () => {},
};

export default Dropdown;
