import { useContext, useEffect, useState } from "react";
import { SegmentsContext } from "../../../../context/Segments";
import {
  conditionFixture,
  includedPageContainersFixture,
  excludedPageContainersFixture,
} from "../../../../fixtures/filters";
import {
  fetchReportById,
  saveReport,
  updateReport,
} from "../../../../lib/firebase/reports";
import {
  deepCopy,
  ellipsize,
  mergeFiltersOutPageView,
  removeEmptyPageConditions,
} from "../../../../utils";
import { AuthContext } from "../../../auth/FirebaseAuthContext";
import { UserDataContext } from "../../UserData";

const useReportModal = (props) => {
  const { type, showCompareMode } = props;
  const funnelId = props.match.params.funnelId;
  const { action, setAction, setIsSaveModalVisible } =
    useContext(UserDataContext);
  const [isBusy, setIsBusy] = useState(false);
  const [inputs, setInputs] = useState({});
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [urlErrorMsg, setUrlErrorMsg] = useState("");
  const [isReportEditor, setIsReportEditor] = useState(true);

  const {
    selectedReport,
    reportNames,
    setReportNames,
    startDate,
    endDate,
    appliedFilters,
    setReports,
    activeProfileId,
    reports,
    setSelectedReport,
    setIsUpdatingReport,
    isDateCompareOn,
    isSegmentCompareOn,
    compareStartDate,
    compareEndDate,
    onlyRecordedSessions,
  } = useContext(UserDataContext);

  const { selectedSegments, comparedSelectedSegments } =
    useContext(SegmentsContext);
  const { authUser } = useContext(AuthContext);

  // Util Function: calculate rolling offset between the date and now
  const getRollingOffset = (date) => {
    return Math.floor(
      (new Date().setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0)) /
        (1000 * 60 * 60 * 24)
    );
  };

  const handleSaveReport = () => {
    setIsSaveModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsSaveModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // name input validation
    if (name === "name") {
      const trimmedValue = value.trim();
      let isDuplicate = false;

      if (trimmedValue === "") {
        setIsNameValid(false);
        setNameErrorMsg("Name is required.");
      } else {
        if (action === "save" || action === "copy") {
          isDuplicate = reportNames.some((rn) => rn.trim() === trimmedValue);
        } else if (action === "update") {
          const selfName = selectedReport?.name.trim() || "";
          isDuplicate = reportNames.some(
            (rn) => rn.trim() === trimmedValue && rn.trim() !== selfName
          );
        }

        setIsNameValid(!isDuplicate);
        setNameErrorMsg(
          isDuplicate
            ? "Ooops! This name already exists, please try another one."
            : ""
        );
      }
    } else if (name === "url") {
      // URL input validation
      const trimmedValue = value.trim();
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      const isValidUrl = urlPattern.test(trimmedValue);

      setIsUrlValid(isValidUrl);
      setUrlErrorMsg(isValidUrl ? "" : "Please enter a valid URL.");
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Submit external report form
  const handleSubmitExternalReport = (e) => {
    e.preventDefault();

    const inputsData = {
      ...inputs,
      shared:
        inputs?.shared === "public" || inputs.shared === true ? true : false,
      description: inputs?.description ?? selectedReport?.description ?? "",
    };

    const settingData = {
      profileId: activeProfileId,
      reportType: "external",
    };

    let reportData = { ...inputsData, ...settingData };

    if (action === "save") {
      setIsBusy(true);
      saveReport(reportData, activeProfileId).then((res) => {
        setIsBusy(false);
        handleCloseModal();
        setAction(null);

        fetchReportById(activeProfileId, res.id).then((res) => {
          const updatedSelectedReport = {
            ...res.data(),
            Id: res.id,
          };

          reportNames.push(updatedSelectedReport.name);
          const updatedReports = [...reports, updatedSelectedReport];
          updatedReports.sort((a, b) => b.updateTime - a.updateTime);
          setReports(updatedReports);
        });
      });
    } else if (action === "update") {
      setIsBusy(true);
      updateReport(reportData, activeProfileId, selectedReport.Id).then(
        (res) => {
          setIsBusy(false);
          setIsUpdatingReport(true);
          handleCloseModal();
          setAction(null);

          fetchReportById(activeProfileId, selectedReport.Id).then((res) => {
            const updatedSelectedReport = {
              ...res.data(),
              Id: res.id,
            };
            setSelectedReport(updatedSelectedReport);

            let currentName;

            reports.forEach((report) => {
              if (report.Id === selectedReport.Id) {
                currentName = report.name;
                Object.assign(report, updatedSelectedReport);
              }
            });

            for (var i = 0; i < reportNames.length; i++) {
              if (reportNames[i] === currentName) {
                reportNames.splice(i, 1);
                reportNames.push(updatedSelectedReport.name);
              }
            }
            setReportNames(reportNames);

            reports.sort((a, b) => b.updateTime - a.updateTime);
            setReports(reports);
            setSelectedReport({});
          });
        }
      );
    }
  };

  // Submit temporary error report for view
  const handleClickSubmitTempError = (
    e,
    errorCondition,
    reportType,
    errorName
  ) => {
    e.preventDefault();

    if (errorCondition) {
      const mergedPageConditions = removeEmptyPageConditions(
        mergeFiltersOutPageView(errorCondition, appliedFilters)
      );

      // Construct the completed filter
      const completedFilter = {
        // Use userConditions from appliedFilters if it has data, otherwise use default
        userConditions:
          Object.keys(appliedFilters).length !== 0 &&
          appliedFilters.userConditions &&
          appliedFilters.userConditions.length > 0
            ? deepCopy(appliedFilters.userConditions)
            : [deepCopy(conditionFixture)],
        // Use the merged page conditions that already include the error condition
        pageContainers:
          Object.keys(appliedFilters).length !== 0
            ? mergedPageConditions
            : [...mergedPageConditions, ...excludedPageContainersFixture],
      };

      const panelData = {
        profileId: activeProfileId,
        filter: JSON.stringify(completedFilter),
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        rollingStart: 0,
        rollingEnd: 0,
        segments: Object.keys(selectedSegments),
        funnelId: null,
      };

      const inputsData = {
        name: ellipsize(errorName, 45),
        reportType: reportType, //avoid add 'report' on the existing report actions: update, copy.
        rolling: false,
        shared: true,
        description: errorName,
      };

      let reportData = { ...inputsData, ...panelData };

      saveReport(reportData, activeProfileId, "temp").then((res) => {
        setIsBusy(false);
        handleCloseModal();
        setAction(null);

        fetchReportById(activeProfileId, res.id, "temp").then((res) => {
          const updatedSelectedReport = {
            ...res.data(),
            Id: res.id,
          };

          setSelectedReport(updatedSelectedReport);

          reportNames.push(updatedSelectedReport.name);
          const updatedReports = [...reports, { ...updatedSelectedReport }];
          updatedReports.sort((a, b) => b.updateTime - a.updateTime);
          setReports(updatedReports);

          // props.history.push(`/profile/${activeProfileId}/reports/${res.id}`);
          window.open(
            `/profile/${activeProfileId}/reports/${"temp"}/${res.id}`,
            "_blank"
          );
        });
      });
      return;
    }
  };

  //Submit general report form
  const handleClickSubmit = (e) => {
    e.preventDefault();

    const panelData = {
      profileId: activeProfileId,
      filter: JSON.stringify(
        Object.keys(appliedFilters).length !== 0
          ? appliedFilters
          : {
              userConditions: [deepCopy(conditionFixture)],
              pageContainers: [
                ...includedPageContainersFixture,
                ...excludedPageContainersFixture,
              ],
            }
      ),
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      rollingStart: getRollingOffset(startDate.toDate()),
      rollingEnd: getRollingOffset(endDate.toDate()),
      segments: Object.keys(selectedSegments),
      funnelId: funnelId || selectedReport?.funnelId || null,
    };

    if (showCompareMode) {
      let comparisonObj;

      if (isDateCompareOn) {
        comparisonObj = {
          type: "date",
          startDate: compareStartDate.toDate(),
          endDate: compareEndDate.toDate(),
          rollingStart: getRollingOffset(compareStartDate.toDate()),
          rollingEnd: getRollingOffset(compareEndDate.toDate()),
        };
      } else if (isSegmentCompareOn) {
        comparisonObj = {
          type: "segment",
          segments: Object.keys(comparedSelectedSegments),
        };
      } else {
        comparisonObj = { type: "none" };
      }

      panelData.comparison = comparisonObj;
    }

    if (
      type === "replay_list" ||
      selectedReport?.reportType === "replay_list"
    ) {
      panelData.recordedSessions = onlyRecordedSessions;
    }

    const inputsData = {
      ...inputs,
      reportType: selectedReport?.reportType || type, //avoid add 'report' on the existing report actions: update, copy.
      rolling:
        inputs?.rolling === "rolling" || inputs?.rolling === true
          ? true
          : false,
      shared:
        inputs?.shared === "public" || inputs.shared === true ? true : false,
      description: inputs?.description ?? selectedReport?.description ?? "",
    };

    setInputs(inputsData);

    let reportData = { ...inputsData, ...panelData };

    if (action === "save" || action === "copy") {
      setIsBusy(true);
      saveReport(reportData, activeProfileId).then((res) => {
        setIsBusy(false);
        handleCloseModal();
        setAction(null);

        fetchReportById(activeProfileId, res.id).then((res) => {
          const updatedSelectedReport = {
            ...res.data(),
            Id: res.id,
          };
          setSelectedReport(updatedSelectedReport);

          reportNames.push(updatedSelectedReport.name);
          const updatedReports = [...reports, { ...updatedSelectedReport }];
          updatedReports.sort((a, b) => b.updateTime - a.updateTime);
          setReports(updatedReports);

          props.history.push(`/profile/${activeProfileId}/reports/${res.id}`);
        });
      });
    } else if (action === "update") {
      setIsBusy(true);
      updateReport(reportData, activeProfileId, selectedReport.Id).then(
        (res) => {
          setIsBusy(false);
          setIsUpdatingReport(true);
          handleCloseModal();
          setAction(null);

          fetchReportById(activeProfileId, selectedReport.Id).then((res) => {
            const updatedSelectedReport = {
              ...res.data(),
              Id: res.id,
            };
            setSelectedReport(updatedSelectedReport);
            let currentName;
            reports.forEach((report) => {
              if (report.Id === selectedReport.Id) {
                currentName = report.name;
                Object.assign(report, updatedSelectedReport);
              }
            });
            for (var i = 0; i < reportNames.length; i++) {
              if (reportNames[i] === currentName) {
                reportNames.splice(i, 1);
                reportNames.push(updatedSelectedReport.name);
              }
            }
            setReportNames(reportNames);

            reports.sort((a, b) => b.updateTime - a.updateTime);
            setReports(reports);

            props.history.push(`/profile/${activeProfileId}/reports/${res.id}`);
          });
        }
      );
    }
  };

  // set report owner
  useEffect(() => {
    if (selectedReport?.ownerId && authUser?.user?.uid) {
      const isOwner = authUser.user.uid === selectedReport.ownerId;
      const isAdmin = authUser.isAdmin;
      setIsReportEditor(isOwner || isAdmin);
    }
  }, [authUser?.user?.uid, authUser?.isAdmin, selectedReport?.ownerId]);

  // Render existing report inputs
  useEffect(() => {
    const fetchSelectedReport = async () => {
      let inputs = {};

      if (activeProfileId && selectedReport.Id) {
        try {
          const res = await fetchReportById(activeProfileId, selectedReport.Id);
          const fetchedReport = res.data();

          if (fetchedReport) {
            const { name, description, shared, url, rolling, reportType } =
              fetchedReport;
            inputs = {
              name,
              description,
              shared,
              ...(reportType === "external" ? { url } : { rolling }),
            };
          }
        } catch (error) {
          console.error("Failed to fetch report:", error);
        }
      }

      setInputs(inputs);
      setIsNameValid(true);
    };

    fetchSelectedReport();
  }, [activeProfileId, selectedReport.Id]);

  return {
    isBusy,
    setIsBusy,
    inputs,
    setInputs,
    isNameValid,
    setIsNameValid,
    nameErrorMsg,
    isUrlValid,
    urlErrorMsg,
    handleChange,
    handleSaveReport,
    handleCloseModal,
    handleClickSubmit,
    handleClickSubmitTempError,
    handleSubmitExternalReport,
    isReportEditor,
  };
};

export default useReportModal;
