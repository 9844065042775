import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Field,
  Button,
} from "./../../../../../components/share/InsightUI";
import { Link } from "react-router-dom";
import { AuthContext } from "./../../../../../components/auth/FirebaseAuthContext";
import { Formik } from "formik";
import { addLog } from "./../../../../../lib/firebase";
import { fetchUser } from "./../../../../../lib/firebase/user";
import { fetchProfileById } from "./../../../../../lib/firebase/profile";
import Alert from "./../../../../../components/share/Alert";
import uiApi from "./../../../../../api/api-ui";
import withProfileAdminCheck from "../../../../../components/hoc/withProfileAdminCheck";

function UserForm(props) {
  const { authUser } = useContext(AuthContext);
  const [isFormBusy, setIsFormBusy] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    name: "",
    role: "",
  });
  const [action, setAction] = useState(null);
  const { profileId, userId } = props.match.params;
  const [alertMessage, setAlertMessage] = useState(null);
  const [dangerAlertMessage, setDangerAlertMessage] = useState(null);

  useEffect(() => {
    if (userId) {
      Promise.all([fetchUser(userId), fetchProfileById(profileId)]).then(
        (res) => {
          const userData = res[0].data();
          const profileData =
            res[1].docs && res[1].docs[0] ? res[1].docs[0].data() : null;
          if (userData.profileIds.indexOf(profileId) === -1) {
            props.history.push(`/profile/${profileId}/admin/users`);
            return;
          }
          if (userData && profileData) {
            let isAdmin = false;
            profileData.admins.forEach((ref) => {
              if (ref.path === `users/${userId}`) {
                isAdmin = true;
              }
            });
            setInitialValues({
              email: userData.email,
              name: userData.name,
              role: isAdmin ? "admin" : "viewer",
            });
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    setIsFormBusy(true);
    setSubmitting(false);
    if (action === "secondary") {
      uiApi.removeProfileUser({ userId, profileId }).then(({ data }) => {
        setIsFormBusy(false);
        setAction(null);
        if (data.success) {
          setAlertMessage(
            <>
              {values.email}'s permission has been updated'. Back to {` `}
              <Link to={`/profile/${profileId}/admin/users`}>Users List</Link>
            </>
          );
        } else {
          setDangerAlertMessage(data.message);
        }
      });
      return;
    } else if (action === "update") {
      uiApi.addProfileUser({ user: values, profileId }).then(({ data }) => {
        if (data.success) {
          const message = `${values.email}'s permission has been updated. Back to `;
          setIsFormBusy(false);
          setAction(null);
          setAlertMessage(
            <>
              {message}
              <Link to={`/profile/${profileId}/admin/users`}>Users List</Link>
            </>
          );
        } else {
          setIsFormBusy(false);
          setAction(null);
          setDangerAlertMessage(data.message);
        }
      });
    } else {
      uiApi
        .inviteUserToProfile(authUser.user.displayName, values, profileId)
        .then(({ data }) => {
          if (data.success) {
            addLog(`invite ${values.name} to ${profileId} as a ${values.role}`);
            setIsFormBusy(false);
            setAction(null);
            setAlertMessage(data.message);
          } else {
            setIsFormBusy(false);
            setAction(null);
            setDangerAlertMessage(data.message);
          }
        });
    }
  };

  return (
    <>
      <div className="row">
        {!!alertMessage && (
          <Alert
            show={alertMessage}
            type={`success`}
            message={alertMessage}
            count={1}
          />
        )}
        {!!dangerAlertMessage && (
          <Alert
            show={dangerAlertMessage}
            type={`danger`}
            message={dangerAlertMessage}
            count={1}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={() => {}}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  // touched,
                  handleChange,
                  // handleBlur,
                  // setFieldValue,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                    <Field
                      inline
                      label="Email"
                      columnwidths={[2, 8]}
                      isInvalid={errors.email}
                      invalidFeedback={errors.email}
                    >
                      <input
                        value={values.email || ""}
                        onChange={handleChange}
                        name="email"
                        className="form-control"
                        type="email"
                        readOnly={userId}
                        required
                      />
                    </Field>
                    <Field inline label="Name" columnwidths={[2, 8]}>
                      <input
                        value={values.name || ""}
                        onChange={handleChange}
                        name="name"
                        className="form-control"
                        type="text"
                        readOnly={userId}
                        required
                      />
                    </Field>
                    <Field inline label="Permission" columnwidths={[2, 4]}>
                      <select
                        value={values.role || ""}
                        className="form-control form-control"
                        name="role"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select a Role</option>
                        <option value="admin">Admin</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </Field>

                    <div className="row">
                      <div className="offset-md-2 col-md-8">
                        {!userId && (
                          <Button
                            disabled={isFormBusy}
                            style={{
                              paddingLeft: "30px",
                              paddingRight: "30px",
                            }}
                            type="submit"
                            size="small"
                            variant="primary"
                          >
                            {!!isFormBusy && action !== "secondary" && (
                              <>
                                <span className="fa fa-spinner spin"></span>
                                {` `}
                              </>
                            )}
                            {!!isFormBusy && `Inviting New User`}
                            {!isFormBusy && `Invite New User`}
                          </Button>
                        )}
                        {!!userId && (
                          <>
                            <Button
                              disabled={isFormBusy}
                              style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                              onClick={() => {
                                setAction("update");
                                handleSubmit();
                              }}
                              size="small"
                              variant="primary"
                            >
                              {!!isFormBusy && !action && (
                                <>
                                  <span className="fa fa-spinner spin"></span>
                                  {` `}
                                </>
                              )}
                              {!!isFormBusy &&
                                action !== "secondary" &&
                                `Updating Access`}
                              {(!isFormBusy ||
                                (!!isFormBusy && action === "secondary")) &&
                                `Update Access`}
                            </Button>
                            <Button
                              className="ml-2"
                              onClick={() => {
                                setAction("secondary");
                                handleSubmit();
                              }}
                              disabled={isFormBusy}
                              style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                              size="small"
                              variant="danger"
                            >
                              {!!isFormBusy && action === "secondary" && (
                                <>
                                  <span className="fa fa-spinner spin"></span>
                                  {` `}
                                </>
                              )}
                              {!!isFormBusy &&
                                action === "secondary" &&
                                `Removing Access`}
                              {(!isFormBusy ||
                                (!!isFormBusy && action !== "secondary")) &&
                                `Remove Access`}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

UserForm.propTypes = {};
UserForm.defaultProps = {};

export default withProfileAdminCheck(UserForm);
