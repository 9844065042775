import React from "react";
import WidgetNumber from "../WidgetNumber";

const FunnelWidget = (props) => {
  const { funnelStepsData, isFunnelStepsLoading, funnelGraphError } = props;

  const getSessionCount = (index) => funnelStepsData[index]?.SessionCount ?? 0;

  // Calculate the conversion rate and handle edge cases
  const getConversionRate = () => {
    const startCount = getSessionCount(0);
    const endCount = getSessionCount(funnelStepsData.length - 1);
    const rate = startCount ? (endCount / startCount) * 100 : 0;

    if (rate === 0) {
      return "0";
    }
    if (rate > 0 && rate < 0.01) {
      return "< 0.01";
    }
    return rate.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const isDataAvailable = !!funnelStepsData.length && !isFunnelStepsLoading;

  return (
    <div className="row funnel-widget">
      <WidgetNumber
        name="Started Sessions"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={getSessionCount(0)}
        status={isDataAvailable}
        columnClass="col-xl"
        info="Sessions that start the funnel"
      />

      <WidgetNumber
        name="Completed Sessions"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={getSessionCount(funnelStepsData.length - 1)}
        status={isDataAvailable}
        columnClass="col-xl"
        info="Sessions that complete the final step"
      />

      <WidgetNumber
        name="Funnel Conversion Rate"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={getConversionRate()}
        suffix="%"
        status={isDataAvailable}
        columnClass="col-xl"
        color="text-success"
        info="% of sessions that complete the funnel"
      />
    </div>
  );
};

export default FunnelWidget;
