import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import SettingMenu from "./SettingMenu";
import * as Icons from "../../assets/icons/MenuIconProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UserDataContext } from "../UserData";
import { FaArrowCircleUp } from "react-icons/fa";

const ProfileMenu = ({ profileId }) => {
  const { authUser } = useContext(AuthContext);
  const { featureFlags } = useContext(UserDataContext);
  const [isPageAnalysisExpanded, setIsPageAnalysisExpanded] = useState(false);
  const location = useLocation();

  // Keep submenu open when on pages route, collapse when navigating elsewhere
  useEffect(() => {
    const isOnPageAnalysisRoute =
      location.pathname.startsWith(`/profile/${profileId}/pages/`) ||
      location.pathname.startsWith(`/profile/${profileId}/landing-pages/`);

    setIsPageAnalysisExpanded(isOnPageAnalysisRoute);
  }, [location.pathname, profileId]);

  return (
    <>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith(
            "/profile/" + profileId + "/analytics/dashboard"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Dashboard"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/analytics/dashboard"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Dashboard />
          </span>
          <span className="sidebar-menu-title">Dashboard</span>
        </NavLink>
      </nav>
      <nav
        className={
          "submenu-container" +
          (isPageAnalysisExpanded ||
          location.pathname.startsWith(`/profile/${profileId}/pages/`) ||
          location.pathname.startsWith(`/profile/${profileId}/landing-pages/`)
            ? " expanded"
            : "")
        }
      >
        <div
          className="sidebar-menu-item"
          onClick={() => setIsPageAnalysisExpanded(!isPageAnalysisExpanded)}
        >
          <span
            className="nav-menu-icon"
            data-tooltip-id="sidebar-menu-tooltip"
            data-tooltip-content="Page Analysis"
          >
            <Icons.PageAnalysis />
          </span>
          <span className="sidebar-menu-title">Page Analysis</span>
        </div>
        {isPageAnalysisExpanded && (
          <div className="submenu">
            <NavLink
              className="submenu-item"
              exact
              to={"/profile/" + profileId + "/pages/list"}
              activeClassName="active"
              data-tooltip-id="sidebar-menu-tooltip"
              data-tooltip-content="All Pages"
            >
              <span className="nav-menu-icon submenu-icon">
                <Icons.PageAnalysis />
              </span>
              <span className="submenu-title">All Pages</span>
            </NavLink>
            <NavLink
              className="submenu-item"
              exact
              to={"/profile/" + profileId + "/landing-pages/list"}
              activeClassName="active"
              data-tooltip-id="sidebar-menu-tooltip"
              data-tooltip-content="Landing Pages"
            >
              <span className="nav-menu-icon submenu-icon">
                <Icons.LandingPages />
              </span>
              <span className="submenu-title">Landing Pages</span>
            </NavLink>
          </div>
        )}
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/forms/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Form Analysis"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/forms/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.FormAnalysis />
          </span>
          <span className="sidebar-menu-title">Form Analysis</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/funnels/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Funnels"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/funnels/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Funnels />
          </span>
          <span className="sidebar-menu-title">Funnels</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/pathing/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Pathing"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/pathing/sunburst"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Pathing />
          </span>
          <span className="sidebar-menu-title">Pathing</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/replay/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Replays"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/replay/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Replays />
          </span>
          <span className="sidebar-menu-title">Replays</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/notes/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Notes"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/notes/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Notes />
          </span>
          <span className="sidebar-menu-title">Notes</span>
        </NavLink>
      </nav>
      {featureFlags.hasErrorReport !== undefined && (
        <nav
          className={
            "sidebar-menu" +
            (location.pathname.startsWith(
              "/profile/" + profileId + "/errors-dashboard"
            ) ||
            new RegExp(`^/profile/${profileId}/reports/temp/[^/]+/?$`).test(
              location.pathname
            )
              ? " active"
              : "")
          }
          data-tooltip-id="sidebar-menu-tooltip"
          data-tooltip-content="Error Report"
        >
          <NavLink
            className="sidebar-menu-item"
            exact
            to={"/profile/" + profileId + "/errors-dashboard"}
            activeClassName="active"
          >
            <span className="nav-menu-icon">
              <Icons.ErrorsDashboard />
            </span>
            <span className="sidebar-menu-title">
              <span className="mr-3">Errors</span>
              {!featureFlags.hasErrorReport && (
                <span className="upgrade-flag">
                  <FaArrowCircleUp size={18} />
                </span>
              )}
            </span>
          </NavLink>
        </nav>
      )}
      <nav
        className={
          "sidebar-menu" +
          (location.pathname.startsWith("/profile/" + profileId + "/segments/")
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Segments"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/segments/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Segments />
          </span>
          <span className="sidebar-menu-title">Segments</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (new RegExp(`^/profile/${profileId}/reports?/(?!temp/)[^/]+/?$`).test(
            location.pathname
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Saved Reports"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/report/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Reports />
          </span>
          <span className="sidebar-menu-title">Saved Reports</span>
        </NavLink>
      </nav>
      {authUser.isAdmin && <SettingMenu profileId={profileId} />}
      <ReactTooltip
        id="sidebar-menu-tooltip"
        className="tooltip-sm"
        place="right"
      />
    </>
  );
};

export default ProfileMenu;
