import React from "react";
import { Modal } from "../../../share/InsightUI";
import UpgradeFlag from "../../FilterPanel/components/UpgradeFlag";

const EventsFilterModal = ({
  isVisible,
  handleClose,
  eventFilters,
  handleClickFilterChange,
  eventTypes,
  handleClearInteractionEvents,
  handleClearNonInteractionEvents,
  featureFlags,
  navigateToPlanUpgrade,
}) => {
  return (
    <Modal
      width={"645px"}
      isVisible={isVisible}
      title="Event Filter"
      className="event-filter-modal"
      handleClose={handleClose}
    >
      <h6 className="d-flex justify-content-between align-items-center">
        <strong>Interaction Events</strong>
        <a
          href="#"
          className="text-primary small"
          onClick={handleClearInteractionEvents}
        >
          Clear all
        </a>
      </h6>
      <div className="row px-2 py-2 bg-gray-100 mb-4">
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["7"]}
              data-eventid={"7"}
              id="checkbox-7"
              type="checkbox"
              value={7}
            />
            <label htmlFor="checkbox-7">{eventTypes[7].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["13"]}
              data-eventid={"13"}
              id="checkbox-13"
              type="checkbox"
              value={13}
            />
            <label htmlFor="checkbox-13">{eventTypes[13].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["12"]}
              data-eventid={"12"}
              id="checkbox-12"
              type="checkbox"
              value={12}
            />
            <label htmlFor="checkbox-12">{eventTypes[12].name}</label>
          </p>
          <p className="mb-0">
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["11"]}
              data-eventid={"11"}
              id="checkbox-11"
              type="checkbox"
              value={11}
            />
            <label htmlFor="checkbox-11">{eventTypes[11].name}</label>
          </p>
        </div>
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["8"]}
              data-eventid={"8"}
              id="checkbox-8"
              type="checkbox"
              value={8}
            />
            <label htmlFor="checkbox-8">{eventTypes[8].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["14"]}
              data-eventid={"14"}
              id="checkbox-14"
              type="checkbox"
              value={14}
            />
            <label htmlFor="checkbox-14">{eventTypes[14].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["17"]}
              data-eventid={"17"}
              id="checkbox-17"
              type="checkbox"
              value={17}
            />
            <label htmlFor="checkbox-17">{eventTypes[17].name}</label>
          </p>
          <p className="mb-0">
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["98"]}
              data-eventid={"98"}
              id="checkbox-98"
              type="checkbox"
              value={98}
            />
            <label htmlFor="checkbox-98">{eventTypes[98].name}</label>
          </p>
        </div>
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["9"]}
              data-eventid={"9"}
              id="checkbox-9"
              type="checkbox"
              value={9}
            />
            <label htmlFor="checkbox-9">{eventTypes[9].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["15"]}
              data-eventid={"15"}
              id="checkbox-15"
              type="checkbox"
              value={15}
            />
            <label htmlFor="checkbox-15">{eventTypes[15].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["18"]}
              data-eventid={"18"}
              id="checkbox-18"
              type="checkbox"
              value={18}
            />
            <label htmlFor="checkbox-18">{eventTypes[18].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["89"]}
              data-eventid={"89"}
              id="checkbox-89"
              type="checkbox"
              value={89}
              disabled={!featureFlags?.hasErrorReport}
            />
            <label
              className={!featureFlags?.hasErrorReport ? "cursor-pointer" : ""}
              htmlFor="checkbox-89"
              {...(!featureFlags?.hasErrorReport && {
                onClick: () => navigateToPlanUpgrade(),
              })}
            >
              {eventTypes[89].name}
            </label>
            {!featureFlags?.hasErrorReport && (
              <label className="ml-2">
                <UpgradeFlag
                  isIconOnly={true}
                  id={`replay-events-${eventTypes[89].name}`}
                />
              </label>
            )}
          </p>
        </div>
      </div>
      <h6 className="d-flex justify-content-between align-items-center">
        <strong>Non-interaction Events</strong>
        <a
          href="#"
          className="text-primary small"
          onClick={handleClearNonInteractionEvents}
        >
          Clear all
        </a>
      </h6>
      <div className="row px-2 py-2 bg-gray-100">
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["1"]}
              data-eventid={"1"}
              id="checkbox-1"
              type="checkbox"
              value={1}
            />
            <label htmlFor="checkbox-1">{eventTypes[1].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["19"]}
              data-eventid={"19"}
              id="checkbox-19"
              type="checkbox"
              value={19}
            />
            <label htmlFor="checkbox-19">{eventTypes[19].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["4"]}
              data-eventid={"4"}
              id="checkbox-4"
              type="checkbox"
              value={4}
            />
            <label htmlFor="checkbox-4">{eventTypes[4].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["10"]}
              data-eventid={"10"}
              id="checkbox-10"
              type="checkbox"
              value={10}
            />
            <label htmlFor="checkbox-10">{eventTypes[10].name}</label>
          </p>
          <p className="mb-0">
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["88"]}
              data-eventid={"88"}
              id="checkbox-88"
              type="checkbox"
              value={88}
              disabled={!featureFlags?.hasErrorReport}
            />
            <label
              className={!featureFlags?.hasErrorReport ? "cursor-pointer" : ""}
              htmlFor="checkbox-88"
              {...(!featureFlags?.hasErrorReport && {
                onClick: () => navigateToPlanUpgrade(),
              })}
            >
              {eventTypes[88].name}
            </label>
            {!featureFlags?.hasErrorReport && (
              <label className="ml-2">
                <UpgradeFlag
                  isIconOnly={true}
                  id={`replay-events-${eventTypes[88].name}`}
                />
              </label>
            )}
          </p>
        </div>
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["2"]}
              data-eventid={"2"}
              id="checkbox-2"
              type="checkbox"
              value={2}
            />
            <label htmlFor="checkbox-2">{eventTypes[2].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["5"]}
              data-eventid={"5"}
              id="checkbox-5"
              type="checkbox"
              value={5}
            />
            <label htmlFor="checkbox-5">{eventTypes[5].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["16"]}
              data-eventid={"16"}
              id="checkbox-16"
              type="checkbox"
              value={16}
            />
            <label htmlFor="checkbox-16">{eventTypes[16].name}</label>
          </p>
          <p className="mb-0">
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["90"]}
              data-eventid={"90"}
              id="checkbox-90"
              type="checkbox"
              value={90}
              disabled={!featureFlags?.hasErrorReport}
            />
            <label
              className={!featureFlags?.hasErrorReport ? "cursor-pointer" : ""}
              htmlFor="checkbox-90"
              {...(!featureFlags?.hasErrorReport && {
                onClick: () => navigateToPlanUpgrade(),
              })}
            >
              {eventTypes[90].name}
            </label>
            {!featureFlags?.hasErrorReport && (
              <label className="ml-2">
                <UpgradeFlag
                  isIconOnly={true}
                  id={`replay-events-${eventTypes[90].name}`}
                />
              </label>
            )}
          </p>
        </div>
        <div className="col">
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["3"]}
              data-eventid={"3"}
              id="checkbox-3"
              type="checkbox"
              value={3}
            />
            <label htmlFor="checkbox-3">{eventTypes[3].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["6"]}
              data-eventid={"6"}
              id="checkbox-6"
              type="checkbox"
              value={6}
            />
            <label htmlFor="checkbox-6">{eventTypes[6].name}</label>
          </p>
          <p>
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["99"]}
              data-eventid={"99"}
              id="checkbox-99"
              type="checkbox"
              value={99}
            />
            <label htmlFor="checkbox-99">{eventTypes[99].name}</label>
          </p>
          <p className="mb-0">
            <input
              onChange={handleClickFilterChange}
              defaultChecked={eventFilters.map["97"]}
              data-eventid={"97"}
              id="checkbox-97"
              type="checkbox"
              value={97}
              disabled={!!featureFlags?.isApiErrorFlag}
            />
            <label
              className={featureFlags?.isApiErrorFlag ? "cursor-pointer" : ""}
              htmlFor="checkbox-97"
              {...(!!featureFlags?.isApiErrorFlag && {
                onClick: () => navigateToPlanUpgrade(),
              })}
            >
              {eventTypes[97].name}
            </label>
            {!!featureFlags?.isApiErrorFlag && (
              <label className="ml-2">
                <UpgradeFlag
                  isIconOnly={true}
                  id={`replay-events-${eventTypes[97].name}`}
                />
              </label>
            )}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default EventsFilterModal;
