import React, { useContext } from "react";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import Alert from "../../share/Alert";

const withProfileAdminCheck = (WrappedComponent) => {
  return (props) => {
    const { authUser } = useContext(AuthContext);
    
    if (!authUser?.isProfileAdmin) {
      console.warn(`User is not admin, ${props.location.pathname}`);
      return (
        <Alert
          show={true}
          type={"danger"}
          message={
            "You don't have access to the requested URL. Please talk to your administrator if you believe you should have access."
          }
          count={0}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withProfileAdminCheck;
