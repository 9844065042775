import React from "react";
import { FaTimes } from "react-icons/fa";
import "./style.scss";
import { NO_PAGEVIEWS_MESSAGE } from "../../../../constants/log";

const AlertBox = (props) => {
  const { noDataAlert, setNoDataAlert } = props;

  const handleNoDataHidden = () => {
    setNoDataAlert(!noDataAlert);
  };

  if (!noDataAlert) return null;

  return (
    <div className="alert-wrapper">
      {noDataAlert && (
        <div className="alert-box">
          <div className="alert-text">
            <span className="text-content">{NO_PAGEVIEWS_MESSAGE}</span>
          </div>
          <div className="close-btn" onClick={handleNoDataHidden}>
            <FaTimes size={18} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertBox;
