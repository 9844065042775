import { useContext } from "react";
import { UserDataContext } from "../UserData";
import { Widgets } from "../../../constants/widgets";
import {
  mergeFiltersUserConditions,
  mergeFiltersOutPageView,
  seggregatePageContainers,
  ellipsize,
  parseUrlWithNoProtocol,
  removeEmptyPageConditions,
} from "../../../utils";

const useTableFilter = () => {
  const {
    userConditions,
    setUserConditions,
    includedPageContainers,
    setIncludedPageContainers,
    excludedPageContainers,
    setExcludedPageContainers,
    appliedFilters,
    setAppliedFilters,
    setCrossFilterMsg,
    setReloadApi,
  } = useContext(UserDataContext);

  const processChannelValue = (val) => {
    const partsArray = val.split(" / ");
    const result = {
      source: partsArray[0],
      medium: partsArray.length > 1 ? partsArray[1] : "",
    };
    return result;
  };

  function processBrowserValue(val) {
    const parts = val.split(" ");
    let browser = val;
    let browserVersion = "";

    if (parts.length > 1) {
      const lastPart = parts[parts.length - 1];
      if (!Number.isNaN(lastPart)) {
        browser = parts.slice(0, -1).join(" ");
        browserVersion = lastPart;
      }
    }

    const result = {
      browser,
      browserVersion,
    };

    return result;
  }

  const handleCellClick = (val, widget) => {
    const msg = (
      <span>
        <b>{ellipsize(val, 60)}</b> has been applied to the filters.
      </span>
    );

    let clickedConditions = [];

    switch (widget) {
      case Widgets.Channel:
        const channelVal = processChannelValue(val);
        if (channelVal.source === "(direct)") {
          clickedConditions = [
            {
              attribute: "TrafficSource",
              path: "",
              unit: null,
              operator: "is",
              values: [channelVal.source, ""],
            },
          ];
        } else {
          clickedConditions = [
            {
              attribute: "TrafficSource",
              path: "",
              unit: null,
              operator: "is",
              values: [channelVal.source],
            },
            {
              attribute: "TrafficMedium",
              path: "",
              unit: null,
              operator: "is",
              values: [channelVal.medium],
            },
          ];
        }

        break;
      case Widgets.Campaign:
        const value = val === "(not set)" ? "" : val;
        clickedConditions = [
          {
            attribute: "TrafficCampaign",
            path: "",
            unit: null,
            operator: "is",
            values: [value],
          },
        ];
        break;
      case Widgets.TopLandingPage:
        var url = parseUrlWithNoProtocol(val);
        if (!url) {
          break;
        }
        clickedConditions = [
          {
            inclusion: true,
            eventConditions: [
              {
                type: "EntryPage",
                conditions: [
                  {
                    attribute: "PagePath",
                    path: "",
                    unit: "",
                    operator: "is",
                    values: [url.pathname],
                  },
                  {
                    attribute: "Domain",
                    path: "",
                    unit: "",
                    operator: "is",
                    values: [url.hostname],
                  },
                ],
              },
            ],
          },
        ];
        break;
      case Widgets.OS:
        clickedConditions = [
          {
            attribute: "OS",
            path: "",
            unit: null,
            operator: "is",
            values: [val],
          },
        ];
        break;
      case Widgets.Browser:
        const browserVal = processBrowserValue(val);
        clickedConditions = [
          {
            attribute: "Browser",
            path: "",
            unit: null,
            operator: "is",
            values: [browserVal.browser],
          },
          {
            attribute: "BrowserVersion",
            path: "",
            unit: null,
            operator: "is",
            values: [browserVal.browserVersion],
          },
        ];
        break;
      case Widgets.Country:
        clickedConditions = [
          {
            attribute: "Country",
            path: "",
            unit: null,
            operator: "in",
            values: [val],
          },
        ];
        break;
      case Widgets.TopPage:
        clickedConditions = [
          {
            inclusion: true,
            eventConditions: [
              {
                type: "VisitPage",
                conditions: [
                  {
                    attribute: "PageURL",
                    path: "",
                    unit: "",
                    operator: "is",
                    values: [val],
                  },
                ],
              },
            ],
          },
        ];
        break;
      default:
        break;
    }

    // user filters
    const MergeClickedUserConditions =
      (widget === Widgets.Channel ||
        widget === Widgets.Campaign ||
        widget === Widgets.OS ||
        widget === Widgets.Browser ||
        widget === Widgets.Country) &&
      mergeFiltersUserConditions(clickedConditions, appliedFilters);

    setUserConditions(MergeClickedUserConditions || userConditions);

    // page filters
    const mergePageConditions =
      (widget === Widgets.TopLandingPage || widget === Widgets.TopPage) &&
      removeEmptyPageConditions(
        mergeFiltersOutPageView(clickedConditions, appliedFilters)
      );

    const IncludedPageContainersMerged =
      !!mergePageConditions && seggregatePageContainers(mergePageConditions)[0];

    setIncludedPageContainers(
      IncludedPageContainersMerged || includedPageContainers
    );

    setExcludedPageContainers(excludedPageContainers);

    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      userConditions: MergeClickedUserConditions || userConditions,
      pageContainers: mergePageConditions || [
        ...includedPageContainers,
        ...excludedPageContainers,
      ],
    }));
    setReloadApi(true);
    setCrossFilterMsg(msg);
  };

  return {
    handleCellClick,
  };
};

export default useTableFilter;
